import { getInstance } from './instance';

const instance = getInstance();

export const getEmailTemplatesDropdown = () => {
  const url = '/email-campaigns/templates/dropdown/list';

  return instance.get(url);
};

export const getEmailTemplates = (perPage, page, filters = {}) => {
  const url = '/email-campaigns/templates';
  const params = {
    perPage,
    page,
    filters,
  };
  return instance
    .get(url, { params })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getEmailTemplate = (templateId) => {
  const url = `/email-campaigns/templates/${templateId}`;

  return instance.get(url);
};

export const getEmailCampaigns = () => {
  const url = '/email-campaigns/content';

  return instance.get(url);
};

export const deleteEmailCampaign = (campaignId) => {
  const url = `/email-campaigns/campaigns/${campaignId}`;

  return instance.delete(url);
};

export const getOnDemandEmails = () => {
  const url = '/email-campaigns/on-demand-emails';
  return instance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getOnDemandEmail = (id) => {
  const url = `/email-campaigns/on-demand-emails/${id}`;

  return instance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const deleteOnDemandEmails = (campaignId) => {
  const url = `/email-campaigns/on-demand-emails/${campaignId}`;

  return instance.delete(url);
};

export const createOnDemandEmail = (body) => {
  const url = '/email-campaigns/on-demand-emails';

  return instance.post(url, body);
};

export const editOnDemandEmail = (body, onDemandEmailId) => {
  const url = `/email-campaigns/on-demand-emails/${onDemandEmailId}`;

  return instance.patch(url, body);
};

export const getEmailActiveCampaigns = () => {
  const url = '/email-campaigns/campaigns';
  return instance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getEmailCampaign = (campaignId) => {
  const url = `/email-campaigns/campaigns/${campaignId}`;

  return instance.get(url);
};

export const getEmailCampaignContent = (contentId) => {
  const url = `/email-campaigns/content/${contentId}`;

  return instance.get(url);
};

export const getEmailCampaignContentPromissed = (contentId) => {
  const url = `/email-campaigns/content/${contentId}`;

  return instance
    .get(url)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
};

export const createEmailCampaign = (body) => {
  const url = '/email-campaigns/campaigns/';

  return instance.post(url, body);
};

export const updateEmailCampaign = (campaignId, body) => {
  const url = `/email-campaigns/campaigns/${campaignId}`;

  return instance.post(url, body);
};

export const updateCampaignStatus = (campaignId, status) => {
  const url = `/email-campaigns/campaigns/${campaignId}/status`;
  const body = { status };

  return instance.post(url, body);
};

export const getEmailHistory = (leadId, perPage, page, status) => {
  let params = {
    page,
    perPage,
  };
  if (status && status !== 'All') {
    params = { ...params, status };
  }
  const url = `email-campaigns/email-history/lead/${leadId}`;
  return instance.get(url, { params });
};

export const getCampaignHistory = (id) => {
  const url = `email-campaigns/campaigns/${id}/history`;

  return instance
    .get(url)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const getAllCampaignHistory = (perPage, page, startDate, endDate) => {
  const url = 'email-campaigns/campaigns/email-metrics/reports';
  const params = {
    perPage,
    page,
    startDate,
    endDate,
  };
  return instance
    .get(url, { params })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getTotalEmailsSent = () => {
  const url = 'email-campaigns/campaigns/email/total';
  return instance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};
