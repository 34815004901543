import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Formik } from 'formik';
import { isEmpty } from 'lodash-es';
import env from 'environments';
import deliverIcon from 'assets/images/deliver-icon.svg';
import { ProcessingModal } from 'modules/v2/common/AtomicDesign/organisms';
import { DENTIST_ORDER_DATA } from 'modules/onboard/constants';
import {
  updateCustomer,
  generateOnboardOrder,
  getSummary,
  generateGuideOrder,
  generateDraftFromBook,
  generateDentistDraftLinks,
  fetchPreFlightCheck,
  fetchDraftLinks,
  storeOnboardGuideDetails,
  unpublishBook,
} from 'modules/api';
import { getItem } from 'modules/dashboard/utils/legacy';
import {
  PrimaryBlackButton,
  SecondaryBlackButton,
  TertiaryButton,
  GlobalLoader,
  Modal,
} from 'modules/common/components';
import { notification, guidesPacketInformation } from 'modules/common/utils';
import { isDentistOffer } from 'modules/dashboard/utils';
import { getRouteAutoLogin } from 'modules/dashboard/routes/navigation';
import { useFetchSession, useStep, usePoolingSession } from 'modules/onboard/hooks';
import {
  PageTitle,
  PageSubTitle,
  PageDescription,
  SubmitButton,
  GoBackButton,
  ContinueButton,
} from 'modules/onboard/components';
import {
  getRouteBookDetails,
  getRouteGuideDetails,
  getRouteGuideConfirmation,
} from 'modules/onboard/routes/navigation';
import { ROUTE_DENTIST_GUIDES_CATALOG } from 'modules/dashboard/routes/dentist/constants';
import { OnboardTemplate } from '../../templates';
import { setSession } from '../../utils';
import useHubspotTrackCode from '../Common/HubspotTrackCode';
import validationSchema from './validationSchema';
import EditAddress from './components/EditAddress';
import { BookContent, GuideContent } from './components';

import S from './styles';

const BookSummary = ({ history }) => {
  const [getLinksLoopingActive, setGetLinksLoopingActive] = useState(false);
  const [guideDraftId, setGuideDraftId] = useState(null);
  const { refetchSession } = useFetchSession();
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const isDentist = isDentistOffer();
  const PRODUCT = isDentist ? 'guide' : 'book';
  const storedValues = getItem(DENTIST_ORDER_DATA, 'object');
  const { goToNextStep } = useStep();

  useHubspotTrackCode();

  const { mutateAsync: mutateGenerateGuideOrder } = useMutation(generateGuideOrder, {
    onSuccess: async () => {
      const sessionResponse = await refetchSession();
      const newStep = sessionResponse?.data?.data?.step;

      goToNextStep(newStep);
    },
  });

  const { session } = useFetchSession();
  const {
    offer,
    id: sessionId,
    draftId,
    scheduleDate,
    autoLoginToken,
    customer: { email },
  } = session || {};

  const { firstName, lastName, billing } = session?.customer || {};

  const offerCode = offer?.code;

  const digitalBook = session?.book?.links?.pages?.read;
  const websiteBook = session?.book?.links?.pages?.landing;
  const pdfBook = session?.book?.links?.book;

  const billingAddress = session?.customer?.billing;
  const address1 = billingAddress?.address1;
  const city = billingAddress?.city;
  const state = billingAddress?.state;
  const country = billingAddress?.country;
  const zipCode = billingAddress?.zip;

  const initialValues = {
    address1,
    city,
    state,
    zipCode,
    country,
    firstName,
    lastName,
  };

  const queryClient = useQueryClient();
  const { mutate: mutateAddress } = useMutation(updateCustomer, {
    onSuccess: () => {
      queryClient.invalidateQueries('fetchSession');
      notification.success({
        description: 'Address details updated successfully',
      });
    },
    onError: () => {
      notification.error({
        description: 'There was an error. Please try again later.',
      });
    },
  });

  const { data: summaryData } = useQuery(['getSummary', { offerCode, sessionId }], () =>
    getSummary({ offerCode, sessionId }),
  );

  const scheduleData = summaryData?.data?.scheduleData || scheduleDate;

  const handleSubmit = (values) => () => {
    if (isEmpty(values))
      return notification.error({
        description: 'Form was not submitted successfully. Please try again later.',
      });

    const body = {
      billing: {
        address1: values.address1,
        city: values.city,
        country: values.country,
        state: values.state,
        zip: values.zipCode,
        firstName: initialValues.firstName,
        lastName: initialValues.lastName,
      },
    };

    setShowModal(false);
    return mutateAddress(body);
  };

  const { mutate: mutateGenerateOrder, isLoading: isOrderGuideLoading } = useMutation(
    generateOnboardOrder,
    {
      onSuccess: (response) => {
        setSession(response?.data?.session);
      },
      onError: (error) => {
        notification.error({
          description: error?.response.data.errors?.[0] || error?.data?.message || 'Network Error',
        });
      },
    },
  );

  const { mutateAsync: mutateStoreGuideData } = useMutation(storeOnboardGuideDetails);

  const queryString = window.location.search;

  const { mutateAsync: generateDraft, isLoading: isGenerateDraftLoading } = useMutation(
    generateDraftFromBook,
  );

  const {
    mutateAsync: generateDraftLinksMutate,
    isLoading: isGenerateDraftLinksLoading,
  } = useMutation(generateDentistDraftLinks);

  const { mutateAsync: preFlightCheckMutate, isLoading: isPreFlightCheckLoading } = useMutation(
    fetchPreFlightCheck,
  );

  const { mutate: mutateUnpublishBook } = useMutation((payload: { draftId: number }) =>
    unpublishBook({ payload }),
  );

  const { startPooling, stopPooling } = usePoolingSession({
    onSuccess: ({ data }) => {
      const bookLink = data.links?.status === 'modified';

      if (bookLink) {
        setShowLoadingModal(false);
        stopPooling();
        const extraParams = `&redirect=/draft/${session.draftId}`;
        const autoLoginUrl = getRouteAutoLogin(email, autoLoginToken, extraParams);
        return history.push(autoLoginUrl);
      }

      return false;
    },
  });

  const handleEditBookClick = () => {
    setShowLoadingModal(true);
    const payload = { draftId };

    mutateUnpublishBook(payload, {
      onSuccess: () => {
        startPooling();
      },
      onError: () => {
        setShowLoadingModal(false);
        notification.error({
          description: 'Something bad has happened!',
        });
      },
    });

    const extraParams = `&redirect=/draft/${session.draftId}`;
    const autoLoginUrl = getRouteAutoLogin(email, autoLoginToken, extraParams);

    return history.push(autoLoginUrl);
  };

  const handleWelcomeJourneyClick = () => {
    setShowLoadingModal(true);
    const payload = { draftId };

    mutateUnpublishBook(payload, {
      onSuccess: () => {
        startPooling();
      },
      onError: () => {
        setShowLoadingModal(false);
        notification.error({
          description: 'Something bad has happened!',
        });
      },
    });

    const autoLoginUrl = getRouteAutoLogin(email, autoLoginToken);
    return history.push(autoLoginUrl);
  };

  useQuery(
    ['getLinks', { draftId: guideDraftId }],
    () => fetchDraftLinks({ draftId: guideDraftId }),
    {
      enabled: getLinksLoopingActive,
      refetchInterval: 3000,
      onSuccess: ({ data }) => {
        const response = data?.data || {};
        const { status, url } = response;
        if (status === 'failed') {
          notification.error({
            description: 'There was an error generating your guide. Please try again later.',
          });
        }

        if (status === 'ready') {
          const { site } = url || {};
          // Book will not be used for now.
          handleFinishOrder({ site });

          setGetLinksLoopingActive(false);
        }
      },
    },
  );

  const handleFinishOrder = async ({ site }) => {
    const { firstDentist, ...values } = storedValues;
    const frontCover = [firstDentist, ...storedValues?.frontCover];

    const payload = {
      ...values,
      frontCover,
      sessionId,
      landingPage: site,
    };
    await mutateStoreGuideData(payload);
    await mutateGenerateGuideOrder(payload);

    history.replace(getRouteGuideConfirmation(offerCode, queryString));
  };

  const handleOrderGuide = async () => {
    // const { guideId } = storedValues;
    // TO DO: Remove this line If we allow the user buy an unique guide
    const packetId = guidesPacketInformation.id;
    const { practiceName } = storedValues;

    const draft = await generateDraft({ bookId: packetId });
    const draftId = draft?.data?.draftId;
    setGuideDraftId(draftId);

    await preFlightCheckMutate({ draftId });
    await generateDraftLinksMutate({ draftId, practiceName });

    setGetLinksLoopingActive(true);
  };

  if (isOrderGuideLoading) {
    return <GlobalLoader />;
  }

  const handleOrderForLater = async () => {
    const { firstDentist, ...values } = storedValues;
    const frontCover = [firstDentist, ...storedValues?.frontCover];

    const payload = {
      ...values,
      frontCover,
    };

    await mutateStoreGuideData(payload);
    const extraParams = `&redirect=${ROUTE_DENTIST_GUIDES_CATALOG}`;
    const autoLoginUrl = getRouteAutoLogin(email, autoLoginToken, extraParams);

    return history.push(autoLoginUrl);
  };

  const handleClick = () => {
    if (isDentist) {
      history.replace(getRouteGuideDetails(offerCode, queryString));
      return false;
    }

    history.replace(getRouteBookDetails(offerCode, queryString));
  };

  return (
    <>
      <OnboardTemplate currentStep="4" isDentist={isDentist}>
        <div className="bg-neutral-50 w-full mt-[25px] px-[70px] py-[50px] max-[620px]:px-0">
          <S.TitleContainer>
            <PageTitle $noTransform>
              {isDentist ? 'Send for printing' : 'Customize your book'}
            </PageTitle>
            <PageDescription>
              {!isDentist
                ? 'Personalize your first printed book'
                : 'Please make sure information below is correct before submitting your guide for printing.'}
            </PageDescription>
          </S.TitleContainer>

          <PageSubTitle>Your Schedule</PageSubTitle>
          <S.Box>
            <div>
              <S.ScheduleContainer>
                <PageSubTitle>Schedule a Marketing Consultation Call</PageSubTitle>
                <S.ScheduleDescription>{scheduleData}</S.ScheduleDescription>
              </S.ScheduleContainer>
            </div>
          </S.Box>

          {isDentist ? <GuideContent /> : <BookContent bookUrl={pdfBook} />}

          <S.SpaceBetween>
            {!isDentist ? null : (
              <>
                <S.Line />
                <S.Box>
                  <S.TextFocus>
                    Would you like to have your {PRODUCT}s printed and shipped to you now?
                  </S.TextFocus>
                  <S.FlexPrintContainer>
                    <S.IconWrapper src={deliverIcon} />
                    <S.AdressContainer>
                      <div>
                        <p className="black-title">Ship To</p>
                        <span className="black-title">{billing?.address1}</span>
                        <br />
                        <span>{`${billing?.city}, ${billing?.state} ${billing?.zip}`}</span>
                        <br />
                        <span>{billing?.country}</span>
                      </div>
                      <TertiaryButton onClick={() => setShowModal(true)}>Edit</TertiaryButton>
                    </S.AdressContainer>
                  </S.FlexPrintContainer>
                </S.Box>
              </>
            )}
          </S.SpaceBetween>
          <br />
          {isDentist ? (
            <S.ButtonContainer>
              <S.SubmitContainer>
                <div>
                  <GoBackButton onClick={handleClick}>Go Back</GoBackButton>
                </div>
                <SubmitButton
                  loading={
                    isGenerateDraftLinksLoading ||
                    isPreFlightCheckLoading ||
                    isGenerateDraftLoading ||
                    getLinksLoopingActive
                  }
                  onClick={() => {
                    handleOrderGuide();
                    setShowLoadingModal(true);
                  }}
                >
                  Place Order For Free
                </SubmitButton>
                <TertiaryButton onClick={handleOrderForLater}>Order for Later</TertiaryButton>
              </S.SubmitContainer>
            </S.ButtonContainer>
          ) : (
            <div className="flex justify-center">
              <ContinueButton onClick={handleWelcomeJourneyClick}>
                COMPLETE CUSTOMIZING YOUR {PRODUCT.toUpperCase()}
              </ContinueButton>
            </div>
          )}
        </div>
      </OnboardTemplate>

      <Formik enableReinitialize validationSchema={validationSchema} initialValues={initialValues}>
        {({ values, isValid }) => (
          <Modal
            title="Edit Address"
            isOpen={showModal}
            onConfirm={() => setShowModal(false)}
            onCancel={() => setShowModal(false)}
            footer={[
              <S.ModalFooterContainer key={1}>
                <PrimaryBlackButton disabled={!isValid} block onClick={handleSubmit(values)}>
                  Save Changes
                </PrimaryBlackButton>
                <SecondaryBlackButton onClick={() => setShowModal(false)} block>
                  Cancel
                </SecondaryBlackButton>
              </S.ModalFooterContainer>,
            ]}
            hasCloseButton
          >
            <S.ModalContainer>
              <EditAddress />
            </S.ModalContainer>
          </Modal>
        )}
      </Formik>

      <Modal
        title="Reschedule Meeting"
        isOpen={showRescheduleModal}
        onConfirm={() => setShowRescheduleModal(false)}
        onCancel={() => setShowRescheduleModal(false)}
        footer={[
          <S.ModalFooterContainer key={1}>
            <PrimaryBlackButton block onClick={() => setShowRescheduleModal(false)}>
              OKAY
            </PrimaryBlackButton>
          </S.ModalFooterContainer>,
        ]}
        hasCloseButton
      >
        <S.ModalRescheduleContainer>
          <p>If you need to reschedule, please contact sales at {env.SUPPORT_PHONE}.</p>
        </S.ModalRescheduleContainer>
      </Modal>
      <ProcessingModal
        title="Processing your order"
        description="Please wait..."
        showModal={showLoadingModal}
      />
    </>
  );
};

export default BookSummary;
