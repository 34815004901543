import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { ArrowUpIcon, DatabaseIcon } from 'modules/v2/common/components/SvgIcon';
import { getRoutePlansAndBillings } from 'modules/v2/routes/navigation';
import { DashboardTemplate } from 'modules/v2/templates';
import OverviewSection from './OverviewSection';

export default function CancelPlan() {
  const subHeader = (
    <div className="w-full flex gap-2 mb-[22px] h-[20px]">
      <Link to={getRoutePlansAndBillings()} className="text-neutral-700">
        Plan and billing
      </Link>
      <div className="text-neutral-700 rotate-90">
        <ArrowUpIcon fill="#757575" />
      </div>
      <div
        className={classNames(
          'border-b-[1px] border-primary-500 font-bold',
          'text-sm whitespace-nowrap',
        )}
      >
        Cancel Plan
      </div>
    </div>
  );

  return (
    <DashboardTemplate hasSidebar title="Plans" subHeader={subHeader}>
      <OverviewSection />
    </DashboardTemplate>
  );
}
