import { map } from 'lodash-es';

import { NavBar } from 'modules/v2/nav/components';
import { TYPE_MANUSCRIPT } from 'modules/v2/editor/utils';
import {
  BooksIcon,
  SectionsIcon,
  ManuscriptIcon,
  CoverIcon,
} from 'modules/v2/common/components/SvgIcons';
import * as color from 'modules/common/theme/color';
import EditorGrid from '../EditorGrid';

const { Dropdown } = NavBar;
const { Item } = NavBar.Menu;

const EditorDropdown = ({ title, items }) => {
  const content = map(items, ({ _id, displayName, to, type, onClick, children }) => {
    if (displayName.toLowerCase() === 'book cover') {
      displayName = 'Covers';
    }

    function getDisplayIcon(value) {
      const iconStyle = '!fill-current !block text-transparent';
      const icons = {
        covers: <CoverIcon className={iconStyle} />,
        'manage sections': <SectionsIcon className={iconStyle} stroke={color.neutral800} />,
        manuscript: <ManuscriptIcon className={iconStyle} stroke={color.neutral800} />,
      };
      return icons[value.toLowerCase()];
    }

    return (
      <Item
        key={_id}
        title={displayName}
        to={to}
        onClick={onClick}
        icon={getDisplayIcon(displayName)}
      >
        <div className="pt-4">
          {type === TYPE_MANUSCRIPT && children && <EditorGrid items={children} />}
        </div>
      </Item>
    );
  });

  const getDropdownTitle = () => {
    let icon;

    switch (title) {
      case 'Manage Sections':
        icon = <SectionsIcon className="mr-2" />;
        break;
      case 'Covers':
        icon = <BooksIcon className="mr-2" />;
        break;
      default:
        icon = <ManuscriptIcon className="mr-2" />;
    }

    return (
      <div className="flex items-center font-figtree">
        {icon}
        <span className="text-neutral-50"> {title}</span>
      </div>
    );
  };

  return (
    <Dropdown className="w-56 rounded-md bg-neutral-700 hover:bg-neutral-900" title={getDropdownTitle()}>
      {content}
    </Dropdown>
  );
};

export default EditorDropdown;
