export default function UserIcon({ width = 16, height = 16 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 7.5C20 10.2614 17.7614 12.5 15 12.5C12.2386 12.5 10 10.2614 10 7.5C10 4.73858 12.2386 2.5 15 2.5C17.7614 2.5 20 4.73858 20 7.5Z"
        stroke="#4CC9E1"
        stroke-width="2"
      />
      <path
        d="M25 21.875C25 24.9816 25 27.5 15 27.5C4.99997 27.5 4.99997 24.9816 4.99997 21.875C4.99997 18.7684 9.47712 16.25 15 16.25C20.5228 16.25 25 18.7684 25 21.875Z"
        stroke="#4CC9E1"
        stroke-width="2"
      />
    </svg>
  );
}
