import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Button } from 'modules/v2/common/AtomicDesign/atoms';

import { toTitleCase } from 'modules/v2/utils/stringManipulation';
import { BookLabel } from 'modules/v2/pages/BookCatalog/components';
import { withBooksStore } from 'modules/v2/containers';
import { setBookLabel, setLabelColor } from 'modules/v2/pages/BookCatalog/utils';

import { getRouteDraftGenerateByBookId } from 'modules/v2/draft/routes/navigation';

import { compose } from 'redux';
import { isLoading } from 'store/status';
import { FrontArrowIcon } from 'modules/v2/common/components/SvgIcons';
import { getRouteBookCatalog } from 'modules/v2/routes/navigation';

import CancelMembershipButton from 'modules/v2/pages/PlansAndBilling/CancelMembership';

import { notification } from 'modules/v2/common/utils';

import { useQuery } from 'react-query';
import { getActiveSubscriptions } from 'modules/api';
import RemainingBenefits from './RemainingBenefits';

// import RemainingBenefits from './RemainingBenefits';
// import Reasons from './Reasons';
// import PauseCard from './PauseCard';
// import RetentByReason from './ReasonRetention';

function OverviewSection({ books, getBooks }) {
  const [cancelStep, setCancelStep] = useState(1);
  const [cancelReason, setCancelReason] = useState('');

  const history = useHistory();

  const [booksList, setBooksList] = useState([]);

  useEffect(() => {
    getBooks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newBooksList = books?.data?.slice(0, 10);

    setBooksList(newBooksList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [books]);

  const { data: subscription = {}, isLoading: isActiveSubscriptionLoading } = useQuery(
    ['getSubscription'],
    getActiveSubscriptions,
    {
      onError: (error) => {
        const errorMessage =
          error.response?.data?.errors?.[0] || error?.data?.message || 'Network Error';
        notification.error({
          description: `${errorMessage}. Please call our customer service at ${env.SUPPORT_PHONE}.`,
          autoClose: false,
        });
      },
    },
  );

  useEffect(() => {
    console.log('subscription');
    console.log(subscription);

    if (!isActiveSubscriptionLoading && (!subscription || subscription?.state === 'canceled')) {
      history.push('/plans-and-billing');
    }
  }, [isActiveSubscriptionLoading]);

  const hasBooks = () => !isLoading(books?.status) && booksList && booksList?.length > 0;

  const handleSeeAllBooks = () => history.push(getRouteBookCatalog());

  const handleCancel = () => {
    if (cancelStep < 4) setCancelStep(cancelStep + 1);
    else {
      window.alert('Canceled');
    }
  };

  const handleGiveUpCancel = () => history.push('/plans-and-billing');

  const BookListage = () => (
    <>
      <div className="mb-4 flex flex-wrap gap-y-4 gap-x-5 justify-center">
        {!hasBooks() ? (
          <div
            role="status"
            className="mb-4 flex flex-wrap gap-y-4 gap-x-5 justify-center overflow-hidden animate-pulse"
          >
            {Array.from({ length: 10 }).map(() => (
              <div>
                <div className="flex items-center justify-center w-[148px] h-[224px] bg-gray-300 rounded dark:bg-gray-700" />
                <div className="h-4 mt-2 bg-gray-300 dark:bg-gray-700 w-full" />
                <div className="h-2.5 mt-1 bg-gray-300 dark:bg-gray-700 w-full" />
              </div>
            ))}
          </div>
        ) : null}

        {hasBooks() &&
          booksList.map((book) => {
            const hasDraft = book?.title;
            return (
              <div key={book._id} className="w-[148px]">
                <Link to={getRouteDraftGenerateByBookId(book._id)} className="relative block">
                  <div className="rounded-md overflow-hidden">
                    <img
                      src={`${book?.thumbnail?.url}?random=33`}
                      height="280px" // setting height directly to avoid CLS
                      className="w-[148px] h-[224px] rounded-md object-cover hover:transform hover:scale-110 transition-transform duration-300 ease-in-out"
                      alt={book.title}
                    />
                  </div>

                  {hasDraft && (
                    <BookLabel
                      label={setBookLabel(book?.links?.status, book?.isAllowed)}
                      className={setLabelColor(book?.links?.status)}
                    />
                  )}

                  <h3 className="text-sm font-medium mt-2 mb-0 pb-0 truncate tracking-normal">
                    {toTitleCase(book.title)}
                  </h3>
                  <div className="text-sm text-neutral-500 font-medium truncate">{book.name}</div>
                </Link>
              </div>
            );
          })}
      </div>
      <div className="relative mt-3">
        <Button type="muted" size="sm" onClick={handleSeeAllBooks}>
          <div className="flex items-center justify-center">
            View All <FrontArrowIcon className="ml-2" />
          </div>
        </Button>
      </div>
    </>
  );

  // const isRetentionStep = () => cancelReason && cancelStep === 3;

  // const CancelSteps = (
  //   <>
  //     <RemainingBenefits cancelStep={cancelStep} />

  //     {cancelStep === 1 && <BookListage />}

  //     {cancelStep === 2 && (
  //       <Reasons
  //         setStep={setCancelStep}
  //         onReasonChange={(reason) => setCancelReason(reason)}
  //       />
  //     )}

  //     {cancelStep === 4 && (
  //       <PauseCard />
  //     )}
  //   </>
  // )

  return (
    <div className="bg-white p-6 rounded-lg shadow-md min-h-[50vh] flex flex-col items-center overflow-hidden">
      <RemainingBenefits cancelStep={cancelStep} />

      <BookListage />

      <hr className="w-[110%] h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

      <div className="flex flex-wrap items-center justify-center gap-4">
        {cancelStep !== 2 && <CancelMembershipButton subscription={subscription} />}

        <Button
          className={cancelStep === 2 ? 'max-w-[528px] min-w-[528px]' : ''}
          type={cancelStep === 2 ? 'primary' : 'outlined'}
          onClick={handleGiveUpCancel}
        >
          Keep My Membership
        </Button>
      </div>
    </div>
  );
}

export default compose(withBooksStore)(OverviewSection);
