import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { get } from 'lodash-es';
import { setItem } from 'modules/v2/utils/legacy';
import { withAuth } from 'modules/v2/containers';
import { AutoLoginCheck } from 'modules/v2/components';
import { CUSTOMER_EVENT } from 'modules/v2/common/constants';
import { registerCustomerEvent } from 'modules/api';
import { getLoginAuth } from 'modules/api/auth';

const AutoLoginCheckContainer = ({ autoLoginCheck, location, auth, history }) => {
  const { mutate: registerCustomerEventMutate } = useMutation(registerCustomerEvent);
  const searchParams = new URLSearchParams(document.location.search);

  useEffect(() => {
    const { username, token } = qs.parse(location.search, { decode: false });

    autoLoginCheck({ username, token });
  }, [autoLoginCheck, location.search]);

  useEffect(() => {
    const fetchData = async () => {
      const data = get(auth, 'login.data', {});

      let welcomeJourneyEligible = false;
      try {
        const user = await getLoginAuth(data?.properties?.email);
        if (user?.welcomeJourneyEligible) {
          welcomeJourneyEligible = true;
        }
      } catch (error) {
        welcomeJourneyEligible = false;
      }

      let redirectParams;

      if (data.token) {
        const userDataUpdated = data?.properties;
        userDataUpdated.welcomeJourneyEligible = welcomeJourneyEligible;
        setItem('token', data.token);
        setItem('refresh_token', data.refresh_token);
        setItem('userData', userDataUpdated);

        registerCustomerEventMutate(CUSTOMER_EVENT.ONBOARD_AUTO_LOGIN);

        if (searchParams.get('redirect')) {
          redirectParams = searchParams.get('redirect');
        } else {
          redirectParams = '/';
        }

        // TODO: we need to do a hard refresh to load the new DMP version if the user has access to it
        window.location.href = redirectParams;

        // TODO: when DMP is full available we can use this code again and remove the hard refresh
        // history.push(redirectParams);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, history]);

  const hasError = !auth.loading && auth.loaded;
  const isLoading = auth.loading && !auth.loaded;

  return <AutoLoginCheck hasError={hasError} isLoading={isLoading} />;
};

AutoLoginCheckContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  auth: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    login: {
      statusCode: PropTypes.number.isRequired,
      data: {
        token: PropTypes.string,
        refresh_token: PropTypes.string.isRequired,
        properties: PropTypes.properties,
      },
    },
  }).isRequired,
  autoLoginCheck: PropTypes.func.isRequired,
};

export default withRouter(withAuth(AutoLoginCheckContainer));
