import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { DashboardTemplate } from 'modules/v2/templates';
import { SubMenu } from 'modules/v2/common/AtomicDesign/molecules';
import { getBillingHistory } from 'modules/api';
import { PlansProvider } from './context';
import Invoices from './Invoices';
import PaymentMethods from './PaymentMethods';
import YourPlan from './YourPlan';

const PlansAndBilling = () => {
  const [subMenuItems, setSubMenuItems] = useState([
    {
      title: 'Your plan',
      id: 'your-plan',
      active: true,
    },
    {
      title: 'Payment methods',
      id: 'payment-methods',
      active: false,
    },
    {
      title: 'Invoices',
      id: 'invoices',
      active: false,
    },
  ]);

  const { data: { data: billingHistory } = [] } = useQuery(
    ['getBillingHistory'],
    getBillingHistory,
  );

  function removeInvoicesMenuIfNoBillingHistory() {
    if (!billingHistory) {
      setSubMenuItems(subMenuItems.filter((item) => item.id !== 'invoices'));
    }
  }

  useEffect(() => {
    removeInvoicesMenuIfNoBillingHistory();
  }, [billingHistory]);

  const changeActiveItem = (id) => {
    const newItems = subMenuItems.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          active: true,
        };
      }
      return {
        ...item,
        active: false,
      };
    });
    setSubMenuItems(newItems);
  };

  const [page, setPage] = useState('your-plan');

  const subHeader = <SubMenu changeActiveItem={changeActiveItem} items={subMenuItems} />;

  const showCurrentComponent = (id) => {
    switch (id) {
      case 'your-plan':
        return <YourPlan changeActiveItem={changeActiveItem} />;
      case 'payment-methods':
        return <PaymentMethods />;
      case 'invoices':
        return <Invoices />;
      default:
        return <YourPlan setPage={setPage} />;
    }
  };

  return (
    <DashboardTemplate hasSidebar title="Plans and billing" subHeader={subHeader}>
      <PlansProvider>
        {showCurrentComponent(subMenuItems.find((item) => item.active).id)}
      </PlansProvider>
    </DashboardTemplate>
  );
};

export default PlansAndBilling;
