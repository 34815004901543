export const toTitleCase = (str: string): string => {
  if (!str) return '';
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const normalizeUrl = (url: string) => {
  if (!url) return '';
  return url.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
