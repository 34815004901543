import { useEffect, useRef, useState, useCallback } from 'react';
import { compose } from 'redux';
import { Col, Row } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { isEmpty } from 'lodash';

import { DashboardTemplate } from 'modules/v2/templates';
import { HiChevronRight } from 'react-icons/hi2';
import { Box, Button, TextInput } from 'modules/v2/common/AtomicDesign/atoms';
import { withPublish } from 'modules/v2/publish/containers';
import { withDraft } from 'modules/v2/draft/containers';
import { fetchUserAddresses } from 'modules/api/user';
import { notification } from 'modules/v2/common/utils';

import DatabaseIcon from 'modules/common/components/SvgIcon/Database';
import ShippingIcon from 'modules/common/components/SvgIcon/ShippingIcon';
import QuantitySelector from 'modules/v2/common/AtomicDesign/molecules/QuantitySelector';
import { ProcessingModal } from 'modules/v2/common/AtomicDesign/organisms';
import { customLoadingModalTheme } from 'modules/v2/pages/OrderedBooksListingPage/Checkout/BookCheckout/theme';
import ConfirmationModal from 'modules/v2/common/AtomicDesign/organisms/ConfirmationModal';

import { generateOrder } from 'modules/api/order';
import { getRouteMyOrders, getRouteHomePage } from 'modules/v2/routes/navigation';
import { useCustomerPlanInfo } from 'modules/v2/utils';
import {
  BOOK_SETUP_TASKS_CHECK,
  TASK_STATUS,
} from 'modules/v2/pages/WelcomeJourney/utils/constants';
import {
  MINIMUM_CHECKOUT_BOOK_QUANTITY,
  MINIMUM_CHECKOUT_BOOK_QUANTITY_FOR_DIGITAL_SUB,
} from 'modules/v2/pages/constants';
import { postJourneyTodos } from 'modules/api/common';
import useTodos from 'modules/v2/context/JourneyContextReducer';
import PaymentCardsSkeleton from 'v2/common/AtomicDesign/atoms/PaymentCardsSkeleton';
import { AddressFormView } from 'v2/common/views/AddressView/views';
import { AddCircleIcon } from 'v2/common/components/SvgIcon';
import { withAddress } from 'v2/containers';
import { STATUS_SUCCESS } from 'store/status';
import { fetchPreFlightCheck } from 'modules/api';
import { useTodoJourney } from 'modules/v2/context/JourneyContextProvider';
import { generateClickUrl } from 'modules/v2/pages/WelcomeJourney/utils/NavigationHandler';
import { setItem } from 'modules/dashboard/utils/legacy';
import { getData } from 'modules/auth';

import ShippingNewAddress from './ShippingNewAddress';
import ShippingAddressSelect from './ShippingAddressSelect';

const Checkout = ({
  draft,
  credits,
  getPublish,
  states,
  countries,
  addUserAddresses,
  submitOrder,
  clearPublish,
  order,
}) => {
  const history = useHistory();

  const draftId = draft?.data?.draftId;
  const draftTitle = draft?.data?.name;
  const [isBookQuantityValid, setIsBookQuantityValid] = useState(true);
  const [bookQuantity, setBookQuantity] = useState(8);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [showShippingModal, setShowShippingModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [updatedShippingAddressList, setUpdatedShippingAddressList] = useState();
  const [isReadyToPreFlightCheck, setIsReadyToPreFlightCheck] = useState(false);
  const [welcomeJourneyFlow, setWelcomeJourneyFlow] = useState(false);
  const [passwordTaskCompleted, setPasswordTaskCompleted] = useState(false);
  const addressFormRef = useRef();
  const location = useLocation();
  const { todos: fetchedTodos } = useTodoJourney();
  const { completed, initializeTodos, tasks, todos } = useTodos();

  const customerPlanInfo = useCustomerPlanInfo();
  const userCredits = Number.isNaN(+credits?.data) ? 0 : parseInt(credits.data, 10);
  const { trialCreditsInfo } = customerPlanInfo;

  useEffect(() => {
    initializeTodos(fetchedTodos);
  }, [fetchedTodos]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('welcomeJourney')) {
      setWelcomeJourneyFlow(true);
    } else {
      setWelcomeJourneyFlow(false);
    }
  }, [location.search]);

  useEffect(() => {
    if (!isEmpty(tasks)) {
      const setupPasswordTask = todos.find((item) => item.name === BOOK_SETUP_TASKS_CHECK);
      if (isEmpty(setupPasswordTask)) {
        setPasswordTaskCompleted(true);
      }
      if (setupPasswordTask && setupPasswordTask?.status === TASK_STATUS.COMPLETED) {
        setPasswordTaskCompleted(true);
      }
    }
  }, [tasks, todos]);

  useEffect(() => {
    if (customerPlanInfo?.isDigital && !customerPlanInfo?.canOrderMoreThanTenBooks) {
      if (userCredits <= MINIMUM_CHECKOUT_BOOK_QUANTITY_FOR_DIGITAL_SUB) {
        setBookQuantity(MINIMUM_CHECKOUT_BOOK_QUANTITY_FOR_DIGITAL_SUB);
      }
    }
    if (customerPlanInfo?.isTrialing && !trialCreditsInfo.isAllowedBuyMoreThanOnce) {
      setBookQuantity(trialCreditsInfo.value);
    }
  }, [customerPlanInfo]);

  const { mutate: mutateGenerateOrder } = useMutation(generateOrder, {
    onSettled: () => {
      setShowLoadingModal(false);
    },
    onError: () => {
      notification.error({
        description: 'Error while processing your request. Please try again later!',
      });
    },
    onSuccess: () => {
      setShowConfirmationModal(true);
    },
  });

  const bookThumbnail = { url: draft?.data?.thumbnail?.url };

  const handleShowShippingModal = () => {
    setShowShippingModal(true);
  };

  const handlePostConfirmation = () => {
    setShowConfirmationModal(false);
    history.push(getRouteMyOrders());
  };

  const handleAddressFormAndSubmit = async () => {
    if (addressFormRef.current) {
      await addressFormRef.current.submit();
    }
    setIsReadyToPreFlightCheck(true);
  };

  const {
    refetch: fetchUserAddressesRefetch,
    isRefetching: isRefetchingUserAddress,
    isLoading: isLoadingUserAddress,
  } = useQuery('fetchUserAddresses', fetchUserAddresses, {
    onSuccess: (res) => {
      const {
        data: { data },
      } = res;
      const { shippingAddress } = data;
      setUpdatedShippingAddressList(shippingAddress);

      const defaultSelectedAddress = shippingAddress.find((address) => address.isDefault === true);
      if (defaultSelectedAddress) {
        setSelectedAddress(defaultSelectedAddress);
      } else if (shippingAddress.length === 1) {
        setSelectedAddress(shippingAddress[0]);
      } else {
        setSelectedAddress(null);
      }
    },
    onError: (error) => {
      notification.error({
        description: error?.response?.data?.errors?.[0] || 'Network Error',
        autoClose: false,
      });
    },
  });

  const handleAddShippingAddress = async (fields) => {
    await addUserAddresses({ ...fields, isDefault: true });
    if (welcomeJourneyFlow) {
      const response = await postJourneyTodos({
        taskId: tasks?.setupShippingAddress?.taskId,
        stepId: tasks?.setupShippingAddress?.stepId?.setupShippingAddress,
      });
      if (response && response.status === 201) {
        completed({
          [tasks?.setupShippingAddress?.name]: TASK_STATUS.COMPLETED,
        });
      }
    }
    await fetchUserAddressesRefetch();
    setIsReadyToPreFlightCheck(true);
  };

  const handleNavigateClick = useCallback(
    (stepName, orderId) => {
      const path = generateClickUrl(stepName);
      history.push(`${path}&completed=setupBookJourney&orderId=${orderId}`);
    },
    [history, tasks],
  );

  const handleNavigateOrderedClick = useCallback(
    (orderId) => {
      const { userData } = getData();
      const updatedUser = {
        ...userData,
        welcomeJourneyEligible: false,
      };
      setItem('userData', updatedUser);
      const path = getRouteHomePage();
      history.push(`${path}?welcomeOrderId=${orderId}&welcomeJourneyCompleted`);
    },
    [history],
  );

  const handleCheckoutSubmit = async () => {
    try {
      if (addressFormRef.current) {
        const validation = await addressFormRef?.current?.validate();
        if (Object.keys(validation).length > 0) {
          return;
        }
      } else if (!selectedAddress) {
        notification.warning({
          description: 'Please select a shipping address',
          autoClose: false,
        });
        return;
      }

      await handleAddressFormAndSubmit();
    } catch (error) {
      notification.error({
        description: error?.response?.data?.errors?.[0] || 'Network Error',
        autoClose: false,
      });
    }
  };

  useEffect(() => {
    const activeUserBookQuantityValid = MINIMUM_CHECKOUT_BOOK_QUANTITY <= bookQuantity <= userCredits
    const trialingUserBookQuantityValid = MINIMUM_CHECKOUT_BOOK_QUANTITY <= trialCreditsInfo.value <= userCredits;
    setIsBookQuantityValid(
      customerPlanInfo?.isTrialing && !trialCreditsInfo.isAllowedBuyMoreThanOnce
        ? trialingUserBookQuantityValid
        : activeUserBookQuantityValid
    );
  }, [userCredits, bookQuantity, credits?.data]);

  useEffect(() => {
    getPublish(draftId);
  }, [draftId, getPublish]);

  const handlePreflightCheck = async () => {
    try {
      setShowLoadingModal(true);
      await fetchPreFlightCheck({ draftId: draft?.data?.draftId });
      const response = submitOrder({
        draftId: draft?.data?.draftId,
        isDigital: false,
        numberOfPrints: customerPlanInfo?.isTrialing && !trialCreditsInfo.isAllowedBuyMoreThanOnce ? trialCreditsInfo.bookCredits : bookQuantity,
        selectedAddressId: selectedAddress._id,
      });
    } catch (error) {
      setShowLoadingModal(false);
      notification.error({
        description: error?.response?.data?.errors?.[0] || 'Error during pre-flight check',
        autoClose: false,
      });
    }
  };

  useEffect(() => {
    if (isReadyToPreFlightCheck) {
      handlePreflightCheck();
      setIsReadyToPreFlightCheck(false);
    }
  }, [isReadyToPreFlightCheck]);

  useEffect(() => {
    if (order.status === STATUS_SUCCESS) {
      setShowLoadingModal(false);
      clearPublish();

      if (welcomeJourneyFlow) {
        const orderId = order?.data?.orderId;
        if (passwordTaskCompleted) {
          handleNavigateOrderedClick(orderId);
        } else {
          handleNavigateClick('', orderId);
        }
      } else {
        setShowConfirmationModal(true);
      }
    }
  }, [order]);

  const BookThumbnail = ({ src, alt, className }) => (
    <div
      className={`h-32 md:h-48 overflow-hidden rounded-lg relative w-auto transition-transform duration-200 ${className}`}
    >
      <img
        src={src}
        alt={alt}
        className="h-full max-w-none object-cover rounded-lg transition-transform duration-200"
      />
    </div>
  );

  const renderInLineAddShippingAddress = () => {
    if (isLoadingUserAddress || isRefetchingUserAddress) {
      return <PaymentCardsSkeleton />;
    }
    return updatedShippingAddressList.length === 0 ? (
      <AddressFormView
        countries={countries.data}
        states={states.data}
        onSubmit={handleAddShippingAddress}
        hasLeadingMessage={false}
        hasSubmitButton={false}
        hasDefaultToggle={false}
        addressFormRef={addressFormRef}
      />
    ) : (
      <Button onClick={handleShowShippingModal} type="bare">
        <AddCircleIcon className="mr-2" /> Add new address
      </Button>
    );
  };

  return (
    <DashboardTemplate hasSidebar title="Checkout">
      <div id="breadcrumbs" className="flex gap-2 items-center">
        <span className="cursor-pointer hover:bg-neutral-100 lowercase first-letter:capitalize">
          {draftTitle}
        </span>
        <HiChevronRight />

        <span className="border-b-[1px] border-neutral-300">Review order</span>
      </div>
      <Box className="mt-6">
        <div className="flex justify-between items-center border-b-[1px] p-7">
          <span className="text-lg font-semibold">Review your order</span>
        </div>

        <div className="p-7 flex max-[1365px]:flex-col gap-6">
          <div className="w-1/2 max-[1365px]:w-full">
            <div id="available-credits" className="justify-items-start mb-7">
              <div className="flex gap-2 items-center mb-4">
                <DatabaseIcon /> <span className="text-sm font-semibold">Available credits</span>
              </div>
              <TextInput disabled value={`${userCredits} credits`} />
            </div>

            <div id="shipping-address" className="justify-items-start">
              <div className="flex gap-2 items-center mb-4">
                <ShippingIcon /> <span className="text-sm font-semibold">Ship to</span>
              </div>
              <ShippingAddressSelect
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
                shippingAddressList={updatedShippingAddressList}
                isRefetching={isRefetchingUserAddress}
              />
              <span className="flex items-center">{renderInLineAddShippingAddress()}</span>
              <div className="bg-neutral-100 rounded-lg p-4 mb-5 mt-5">
                <span className="text-sm text-neutral-700 flex items-center font-medium">
                  Any images added to the books will be printed in black and white
                </span>
              </div>
            </div>
          </div>
          <div className="w-1/2 max-[1365px]:w-full">
            <div id="summary" className="justify-items-start bg-neutral-50 rounded-lg p-7">
              <span className="text-2xl font-semibold">Summary</span>

              <div className="mt-7 gap-[14px] flex max-[510px]:flex-col max-[510px]:items-center">
                <div className="flex gap-2 items-center mb-2">
                  <BookThumbnail
                    src={bookThumbnail?.url}
                    alt={draftTitle}
                    className="group-hover:scale-105"
                  />
                </div>

                <div>
                  <div className="flex gap-2 items-center mb-2">
                    <span className="text-sm font-semibold lowercase first-letter:capitalize">
                      {draftTitle}
                    </span>
                  </div>
                  <QuantitySelector
                    onChange={({ target: { value } }) => setBookQuantity(value)}
                    value={customerPlanInfo?.isTrialing && !trialCreditsInfo.isAllowedBuyMoreThanOnce ? trialCreditsInfo.bookCredits : bookQuantity}
                    maximum={userCredits}
                    minimum={MINIMUM_CHECKOUT_BOOK_QUANTITY}
                    disabled={
                      customerPlanInfo?.isTrialing && !trialCreditsInfo.isAllowedBuyMoreThanOnce ||
                      userCredits < MINIMUM_CHECKOUT_BOOK_QUANTITY
                    }
                    step={4}
                    isValid={isBookQuantityValid}
                    errorMessage={
                      userCredits ? `Please enter a number between 8 and ${userCredits}` : ''
                    }
                    containerClassName="justify-start max-[510px]:justify-center"
                  />
                </div>
              </div>

              <div className="flex w-full justify-between items-center mt-6 mb-6">
                <div className=''>
                  <span className="text-sm text-neutral-400">Shipping</span>
                </div>

                <div className="">
                  <span className="text-sm text-neutral-400">free</span>
                </div>
              </div>

              <hr className="w-full border border-neutral-200 my-4" />

              <div className="flex w-full justify-between items-center mt-6 mb-6">
                <div className=''>
                  <span className="text-base font-semibold">Total due today</span>
                </div>

                <div className="">
                  <span className="text-base font-semibold">{bookQuantity} credits</span>
                </div>
              </div>

              <Row className="mt-7 w-full">
                <Button
                  onClick={handleCheckoutSubmit}
                  wrapperClassName="w-full"
                  full
                  disabled={!isBookQuantityValid || isRefetchingUserAddress}
                >
                  Place order
                </Button>
              </Row>
            </div>
          </div>
        </div>
      </Box>

      <ShippingNewAddress
        openAddressForm={showShippingModal}
        setOpenAddressForm={setShowShippingModal}
        fetchUserAddressesRefetch={fetchUserAddressesRefetch}
        fromWelcomeJourney={welcomeJourneyFlow}
      />

      <ProcessingModal
        title="Processing your order..."
        description="Please don’t close this window"
        showModal={showLoadingModal}
        setShowModal={setShowLoadingModal}
        theme={customLoadingModalTheme}
      />

      <ConfirmationModal
        title="Thank you. Your order is confirmed!"
        description="Please allow 7-10 days for us to print and ship your order."
        showModal={showConfirmationModal}
        setShowModal={setShowConfirmationModal}
        onConfirmation={handlePostConfirmation}
        confirmationText="View order"
      />
    </DashboardTemplate>
  );
};

Checkout.defaultProps = {
  draft: {},
  credits: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getPublish: () => { },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addUserAddresses: () => { },
  countries: [],
  states: [],
};

export default compose(withPublish, withDraft, withAddress)(Checkout);
