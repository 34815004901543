import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { compose } from 'redux';
import { find, get } from 'lodash-es';
import closeIcon from 'assets/images/closeIcon.svg';

import {
  withBook,
  withCover,
  withDraft,
  withImageLibrary,
  withPreferences,
} from 'modules/v2/editor/containers';

import { CoverEditor, CoverEditorModal } from 'modules/v2/editor/components';

import { Button, SwitchButton } from 'modules/v2/common/AtomicDesign/atoms';
import { ControlItem } from 'modules/v2/common/AtomicDesign/molecules';
import { DeleteModal } from 'modules/v2/common/AtomicDesign/organisms';
import { CoverIcon, SettingsIcon, ResetIcon } from 'modules/v2/common/components/SvgIcons';
import { COVER_IMAGE_GROUP_UPLOAD } from 'modules/v2/editor/utils/constants';
import { EditorTemplate } from 'modules/v2/editor/templates';
import { BROKER_CONTACT } from 'modules/v2/editor/components/CoverEditor/components/CoverElement/utils';
import TemplatesList from './TemplatesList';

const getSection = (draft) => {
  const draftSections = get(draft, ['data', 'sections'], []);
  const draftSection = find(draftSections, ({ type }) => type === 'Cover');

  return draftSection;
};

const getCover = (book, draft) => {
  const draftSection = getSection(draft);
  const draftTemplates = get(draftSection, 'templates', []);
  const draftTemplate = find(draftTemplates, ({ isActive }) => isActive);

  const bookSections = get(book.data, 'sections', []);
  const bookSection = find(bookSections, ({ _id }) => _id === draftSection._id);
  const bookTemplates = get(bookSection, 'templates', []);
  const bookTemplate = find(bookTemplates, ({ _id }) => _id === draftTemplate._id);

  // TODO: Move to selectors
  const templateHTML = get(bookTemplate, 'templateHTML', '');

  return { templateHTML };
};

export const Cover = ({
  book,
  draft,
  draftId,
  elements,
  images,
  loadingElements,
  logos,
  templateId,
  toolbar,
  preferences,
  deleteUserImage,
  getUserImages,
  getPresetLogos,
  replaceCoverImage,
  resetDraft,
  resetCurrentSection,
  toggleShowGuides,
  uploadCoverImage,
  updateCoverElement,
  updatePreferences,
  updatePreferenceStatusByKey,
  updatePreferenceByKey,
} = {}) => {
  const [elementId, setElementId] = useState(null);
  const [activeGuides, setActiveGuides] = useState(false);
  const [activeControls, setActiveControls] = useState('covers');
  const [showResetFactoryModal, setShowFactoryResetModal] = useState(false);
  const [showResetManuscriptModal, setShowManuscriptResetModal] = useState(false);
  const [scale, setScale] = useState(1);

  const brokerLogo = get(preferences, ['brokerLogo', 'status'], true);
  // const realtorLogo = get(preferences, ['realtorLogo', 'status'], false);
  // const realtorLogo = false;
  const brokerInfo = get(preferences, ['brokerInfo', 'status'], true);
  const housingLogo = get(preferences, ['housingLogo', 'status'], true);
  const { startChaptersInRecto } = preferences;
  const element = find(elements, (e) => e?._id === elementId);

  useEffect(() => {
    const contactInfoEl = find(elements, (e) => e?.id === BROKER_CONTACT);
    if (contactInfoEl?.defaultValue === '<p><br/></p>' || contactInfoEl?.defaultValue === '') {
      setElementId(contactInfoEl._id);
    }
  }, []);

  // TODO: Move templateHTML, showGuides, bookTitle, sections and dropDownItems to the selectors
  const { templateHTML } = getCover(book, draft);
  const { _id: sectionId } = getSection(draft);

  const { showGuides } = toolbar;

  const toggleGuides = () => {
    const newValue = !activeGuides;

    toggleShowGuides(newValue);
    setActiveGuides(newValue);
  };

  const buildHandleShowControl = (control) => () => {
    if (activeControls === control) {
      return setActiveControls(null);
    }
    return setActiveControls(control);
  };

  const handleCloseControls = () => setActiveControls(null);

  const updatePreference = (key) => () => {
    const checked = get(preferences, [key, 'status'], false);

    if (key === 'startChaptersInRecto') {
      updatePreferenceByKey(key, !preferences[key]);
      return;
    }

    updatePreferenceStatusByKey(key, !checked);
  };

  const handleFilePickerConfirm = (value, group, imageFormat) => {
    if (group === COVER_IMAGE_GROUP_UPLOAD) {
      uploadCoverImage(templateId, elementId, value, group, imageFormat);
      return setElementId(null);
    }

    replaceCoverImage(templateId, elementId, value, group, imageFormat);
    return setElementId(null);
  };

  const handleRichTextConfirm = ({ rawValue, defaultValue }) => {
    updateCoverElement(templateId, elementId, rawValue, defaultValue);
    setElementId(null);
  };

  const handleModalCancel = () => setElementId(null);

  const handleContactDetailsChange = (fields) => {
    updatePreferences(fields);
    setElementId(null);
  };

  const handleDeleteImage = (payload) => {
    deleteUserImage(payload);
  };

  const handleResetDraft = () => {
    setShowFactoryResetModal(false);
    resetDraft(draftId);
  };

  const handleResetSection = () => {
    setShowManuscriptResetModal(false);
    resetCurrentSection(sectionId);
  };

  return (
    <EditorTemplate dropdownTitle="Covers">
      <div className="w-fit h-[calc(100vh-64px)] text-center border border-1 border-r-neutral-200">
        <ul className="py-2">
          <ControlItem
            icon={<CoverIcon className="group-hover:translate-y-[-4px] duration-200 mb-2" />}
            active={activeControls === 'covers'}
            label="Covers"
            onClick={buildHandleShowControl('covers')}
          />
          <ControlItem
            icon={<SettingsIcon className="group-hover:translate-y-[-4px] duration-200 mb-1" />}
            active={activeControls === 'settings'}
            label="Settings"
            onClick={buildHandleShowControl('settings')}
          />
          <ControlItem
            type="danger"
            active={activeControls === 'reset'}
            icon={<ResetIcon className="group-hover:translate-y-[-4px] duration-200 mb-1" />}
            label="Reset"
            onClick={buildHandleShowControl('reset')}
          />
        </ul>
      </div>

      <div
        className={classnames(
          'transition-all duration-100 overflow-hidden bg-white z-10',
          activeControls ? 'w-[312px]' : 'w-0',
        )}
      >
        {/* toolbar */}

        {activeControls === 'covers' ? (
          <>
            <div className="h-14 px-4 py-3.5 border-b border-neutral-200 gap-6 inline-flex w-full">
              <h3 className="text-zinc-900 text-base font-semibold leading-7 flex-1 w-full">
                Available covers
              </h3>
              <Button type="icon" size="" onClick={handleCloseControls} className="p-[6px]">
                <img src={closeIcon} alt="close" className="w-[16px] h-[16px]" />
              </Button>
            </div>
            <div className="pl-4 pr-4 pt-[24px]">
              <TemplatesList />
            </div>
          </>
        ) : null}

        {activeControls === 'settings' ? (
          <>
            <div className="h-14 px-4 py-3.5 border-b border-neutral-200 gap-6 inline-flex w-full">
              <h3 className="text-zinc-900 text-base font-semibold leading-7 flex-1 w-full">
                Settings
              </h3>
              <Button type="icon" size="" onClick={handleCloseControls} className="p-[6px]">
                <img src={closeIcon} alt="close" className="w-[16px] h-[16px]" />
              </Button>
            </div>
            <div className="pl-4 mt-5">
              <h4 className="mb-3 text-sm font-semibold">Cover preferences</h4>
              <ul className="leading-6">
                <li className="flex mb-4">
                  <SwitchButton
                    checked={brokerLogo}
                    // setCheck={() => {}}
                    onChange={updatePreference('brokerLogo')}
                    className="mr-3"
                  />
                  <span className="flex-1 font-semibold">Include my brokerage logo</span>
                </li>

                {/* <li className="flex mb-4"> */}
                {/*   <SwitchButton */}
                {/*     // checked={realtorLogo} */}
                {/*     onChange={updatePreference('realtorLogo')} */}
                {/*     className="mr-3" */}
                {/*   /> */}
                {/*   <span className="flex-1">Include my realtor logo</span> */}
                {/* </li> */}

                <li className="flex mb-4">
                  <SwitchButton
                    className="mr-3"
                    checked={housingLogo}
                    onChange={updatePreference('housingLogo')}
                  />

                  <span className="flex-1 font-semibold">Include Fair Housing Logo</span>
                </li>

                <li className="flex mb-4">
                  <SwitchButton
                    className="mr-3"
                    checked={brokerInfo}
                    onChange={updatePreference('brokerInfo')}
                  />

                  <span className="flex-1 font-semibold">Include broker info</span>
                </li>

                <li className="flex mb-4">
                  <SwitchButton
                    className="mr-2"
                    checked={startChaptersInRecto}
                    onChange={updatePreference('startChaptersInRecto')}
                  />
                  <span className="flex-1 font-semibold">
                    Always start chapters on the right-hand page
                  </span>
                </li>
              </ul>

              <hr className="my-6 w-[284px]" />

              <h4 className="mb-3 text-sm font-semibold">View Preferences</h4>
              <ul className="">
                <li className="flex mb-4">
                  <SwitchButton className="mr-3" checked={showGuides} onChange={toggleGuides} />

                  <div className="flex-1">
                    <span className="block font-semibold">Show guide</span>
                    <span className="text-neutral-400 w-4/5 block">
                      Highlight elements that are editable
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </>
        ) : null}

        {activeControls === 'reset' ? (
          <>
            <div className="h-14 px-4 py-3.5 border-b border-neutral-200 gap-6 inline-flex w-full">
              <h3 className="text-zinc-900 text-base font-semibold leading-7 flex-1 w-full">
                Reset
              </h3>
              <Button type="icon" size="" onClick={handleCloseControls} className="p-[6px]">
                <img src={closeIcon} alt="close" className="w-[16px] h-[16px]" />
              </Button>
            </div>

            <div className="px-4">
              <h4 className="mt-6 text-sm font-semibold mb-[4px]">Reset this section</h4>
              <p className="leading-[20px] font-medium text-[#818181]">
                This will reset all possible changes made in this section back to its default
                version.
              </p>

              <Button
                type="dangerOutlined"
                className="mt-3.5 mb-6"
                wrapperClassName="p-[0px] border-none"
                onClick={() => setShowManuscriptResetModal(true)}
              >
                <div className="flex items-center">
                  <ResetIcon className="mr-2" />
                  Reset this section only
                </div>
              </Button>

              <hr />
              <h4 className="mt-6 text-sm font-semibold mb-[4px]">Factory reset</h4>
              <p className="leading-[20px] font-medium text-[#818181]">
                This will reset all possible changes made in this book including your headshot photo
                and name and back to its default version.
              </p>

              <Button
                type="danger"
                className="mt-3.5 mb-6"
                wrapperClassName="p-[0px] border-none"
                onClick={() => setShowFactoryResetModal(true)}
              >
                <div className="flex items-center">
                  <ResetIcon className="mr-2" stroke="white" />
                  Factory reset
                </div>
              </Button>
            </div>
          </>
        ) : null}
      </div>

      <div className="flex-1 text-center items-center flex justify-center border border-1 w-20">
        <div className="h-full w-full items-center justify-center bg-neutral-100">
          <CoverEditor
            template={templateHTML}
            elements={elements}
            scale={scale}
            showGuides={showGuides}
            onClick={setElementId}
            onScaleChange={setScale}
            loadingElements={loadingElements}
          />
          <CoverEditorModal
            element={element}
            images={images}
            logos={logos}
            scale={scale}
            preferences={preferences}
            onContactDetailsChange={handleContactDetailsChange}
            onDeleteImage={handleDeleteImage}
            onFilePickerConfirm={handleFilePickerConfirm}
            onModalCancel={handleModalCancel}
            onRichTextConfirm={handleRichTextConfirm}
            onGalleryTabOpen={getUserImages}
            onLogosTabOpen={getPresetLogos}
          />
        </div>
      </div>
      <DeleteModal
        title="Are you sure you want to reset?"
        showModal={showResetManuscriptModal}
        setShowModal={setShowManuscriptResetModal}
        handleDelete={handleResetSection}
        deleteButton="Yes, Reset this section"
      >
        This will reset all possible changes made in this section back to its default version. Don’t
        worry other sections of the book won’t be affected.
      </DeleteModal>
      <DeleteModal
        title="Factory reset?"
        showModal={showResetFactoryModal}
        setShowModal={setShowFactoryResetModal}
        handleDelete={handleResetDraft}
        deleteButton="Yes, Factory reset"
      >
        This will reset all possible changes made in this book including your headshot photo and
        name and back to its default version.
      </DeleteModal>
    </EditorTemplate>
  );
};

const MemoCover = React.memo(Cover);
const Composed = compose(
  withCover,
  withPreferences,
  withBook,
  withDraft,
  withImageLibrary,
)(MemoCover);

export default Composed;
