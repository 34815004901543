import React from 'react';
import PropTypes from 'prop-types';

const AlertSolidWarningIcon = ({ width, height, fill, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.63964 13.4519C10.2876 6.98396 12.1116 3.75 15 3.75C17.8884 3.75 19.7124 6.98395 23.3604 13.4519L23.8149 14.2578C26.8464 19.6326 28.3621 22.32 26.9922 24.285C25.6223 26.25 22.233 26.25 15.4546 26.25H14.5454C7.76695 26.25 4.37771 26.25 3.00782 24.285C1.63793 22.32 3.15364 19.6326 6.18506 14.2578L6.63964 13.4519ZM15 9.0625C15.5178 9.0625 15.9375 9.48223 15.9375 10V16.25C15.9375 16.7678 15.5178 17.1875 15 17.1875C14.4822 17.1875 14.0625 16.7678 14.0625 16.25V10C14.0625 9.48223 14.4822 9.0625 15 9.0625ZM15 21.25C15.6904 21.25 16.25 20.6904 16.25 20C16.25 19.3096 15.6904 18.75 15 18.75C14.3096 18.75 13.75 19.3096 13.75 20C13.75 20.6904 14.3096 21.25 15 21.25Z"
      fill={fill}
    />
  </svg>
);

AlertSolidWarningIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  className: PropTypes.string,
};

AlertSolidWarningIcon.defaultProps = {
  width: '30',
  height: '30',
  fill: '#E4483D',
  className: '',
};

export default AlertSolidWarningIcon;
