import { CoverImage } from 'modules/v2/editor/components';
import { startCase, toLower } from 'lodash-es';

import S from './styles';

const BookCard = ({ name, title, image, to, isAllowed }) => {
  if (isAllowed) {
    return (
      <S.WrapperLink to={to} data-testid="link">
        <div>
          <S.ImageWrapper>
            <CoverImage url={image} isAllowed={isAllowed} noBorder />
          </S.ImageWrapper>
          <span className="block text-sm font-medium text-neutral-800 mt-2 truncate" title={title}>
            {startCase(toLower(title))}
          </span>
          <span className="text-sm font-medium text-neutral-500 block">{name}</span>
        </div>
      </S.WrapperLink>
    );
  }
  return (
    <div>
      <S.ImageWrapper>
        <CoverImage url={image} isAllowed={isAllowed} noBorder />
      </S.ImageWrapper>
      <span className="block text-sm font-medium text-neutral-800 mt-2 lowercase first-letter:uppercase">
        {title}
      </span>
      <span className="block text-sm font-medium text-neutral-500 mt-2">{name}</span>
    </div>
  );
};

BookCard.defaultProps = {
  name: '',
  image: '',
  to: '',
};

export default BookCard;
