import { useEffect, useState } from 'react';
import { get } from 'lodash-es';
import { compose } from 'redux';
import { ROUTE_BUY_CREDITS } from 'v2/routes/constants';
import { useHistory } from 'react-router-dom';
import { getRouteDraftGenerateByBookId } from 'modules/v2/draft/routes/navigation';
import { Checkbox, Label, Tooltip } from 'flowbite-react';
import { useQuery as useReactQuery } from 'react-query';

import empty from 'assets/images/empty.png';
import { Button, TextInput } from 'modules/v2/common/AtomicDesign/atoms';
import { defaultCheckboxTheme } from 'modules/v2/common/AtomicDesign/atoms/Checkbox/theme';
import { RestartIcon } from 'modules/v2/common/components/SvgIcon';

import { isInitial, isLoading, isSucceeded } from 'store/status';
import { notification, pluralize } from 'modules/v2/common/utils';

import { DropdownInput } from 'modules/v2/common/components';

import { Loader } from 'modules/v2/common/components';
import { withAuth } from 'modules/auth/containers';
import { withBooksStore, withReleaseNotes, withUserCredits } from 'modules/v2/containers';
import { DashboardTemplate } from 'modules/v2/templates';
import { fetchUserCredits, getShowCreditsButton } from 'modules/api';
import CanadaFlag from 'assets/images/CanadaFlag.png';
import USFlag from 'assets/images/USFlag.png';
import { BookLabel, BookPreview } from './components';
import { setBookLabel, setLabelColor } from './utils';
import { PaymentSteps } from '../PaymentSteps';
import { useGetBookPreviews } from '../admin/BookPreviews/hooks';
import S from './styles';

export const BookCatalog = ({
  books,
  categories,
  credits,
  headshot,
  getBooks,
  getCategories,
  getUserCredits,
  selectCategory,
  updatePreferences,
}) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const isMobile = windowSize < 768;

  const [booksList, setBooksList] = useState(books?.data);
  const [bookPreviews, setBookPreviews] = useState([]);
  const [showPaymentSteps, setShowPaymentSteps] = useState(false);
  const [showBuyCreditsButton, setShowBuyCreditsButton] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [checked, setChecked] = useState({});

  const [selectedMobileFilters, setSelectedMobileFilters] = useState([]);

  const headshotId = get(headshot, ['data', '_id']);
  const isBooksLoading = isLoading(books.status);

  const history = useHistory();

  const handleClearFilters = () => {
    setChecked({});
    getBooks();
    selectCategory({});
    setSearchValue('');
    setSelectedMobileFilters([]);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (books?.data?.length > 0) {
      const SortedBooks = (a, b) => {
        if (a.isAllowed === b.isAllowed) {
          return new Date(b.updatedAt) - new Date(a.updatedAt);
        }
        return a.isAllowed ? -1 : 1;
      };
      setBooksList(books.data.sort(SortedBooks));
    }
  }, [books]);

  useEffect(() => {
    if (isInitial(categories.status)) {
      getCategories();
    }
    getBooks();

    getUserCredits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useGetBookPreviews({
    pagination: {
      current: 1,
      pageSize: 100,
    },
    onSuccess: (response) => setBookPreviews(response?.data?.data),
    onError: (error) => {
      notification.error({
        title: 'Error',
        description: 'there was an error, please try again later',
      });
      throw new Error(error.message);
    },
  });

  const handleSubmit = (formFields) => {
    const data = {
      ...formFields,
      headshotId,
    };

    updatePreferences(data);
  };

  const handleCategoryChange = (e, categoryId, name) => {
    const isChecked = e.target.checked;

    const copyCheck = checked;
    copyCheck[categoryId] = isChecked;

    console.log('selectedMobileFilters');
    console.log(selectedMobileFilters);

    if (isChecked) {
      setSelectedMobileFilters([...selectedMobileFilters, name]);
    } else {
      setSelectedMobileFilters(selectedMobileFilters.filter((value) => value !== name));
    }

    setChecked(copyCheck);
    selectCategory(checked);
    setSearchValue('');
  };

  let creditsSubHeader = <S.Loading>Loading credits...</S.Loading>;
  let categoriesSubHeader = (
    <span className="w-full pl-4 flex items-center justify-center sm:justify-end">Loading categories...</span>
  );
  let content = <Loader height="calc(100vh - 400px)" title="Loading Books" />;

  const {
    data: userBookCredit,
    isLoading: isCreditLoading,
    refetch: reactCreditQueryRefetch,
  } = useReactQuery(['getUserCredits'], fetchUserCredits);
  const bookCredit = userBookCredit?.data?.data?.credits;

  useReactQuery('getShowCreditsButton', getShowCreditsButton, {
    onSuccess: (results) => {
      setShowBuyCreditsButton(results);
    },
  });

  if (isSucceeded(credits.status)) {
    creditsSubHeader = (
      // eslint-disable-next-line react/no-unknown-property
      <span className="text-base text-neutral-800 mr-2" isCreditEnough={credits.data >= 8}>
        {pluralize('Credit', 'Credits', credits.data)}
      </span>
    );
  }

  if (!isCreditLoading) {
    creditsSubHeader = (
      // eslint-disable-next-line react/no-unknown-property
      <span className="text-base text-neutral-800 mr-2" isCreditEnough={bookCredit >= 8}>
        {pluralize('Credit', 'Credits', bookCredit)}
      </span>
    );
  }

  const mapAsCheckbox = ({ _id, name }) => (
    <div
      className="flex items-center gap-2 ml-4"
      key={_id}
    >
      <Checkbox checked={checked[_id]} theme={defaultCheckboxTheme} id={_id} onClick={(e) => handleCategoryChange(e, _id, name)} />
      <Label className="cursor-pointer" htmlFor={_id}>
        {setCategory(name)}
      </Label>
    </div>
  );

  const getMobileOptions = () => {
    const mapOption = ({ _id, name }, notAllowed = false) => ({
      id: name,
      key: name,
      value: name,
      label: notAllowed ? <span className="font-semibold text-neutral-500 text-sm uppercase">{name}</span> : mapAsCheckbox({ _id, name }),
      selectedLabel: name,
      notAllowed: true
    });

    const categoriesOptions = [];

    const countryGroupingLabel = mapOption({ name: 'Country', _id: 0 }, true);
    categoriesOptions.push(countryGroupingLabel);

    categories?.data?.forEach((option, index) => {
      if (option.name.includes('Spanish')) {
        const languageGroupingLabel = mapOption({ name: 'Language', _id: index }, true);
        categoriesOptions.push(languageGroupingLabel);
      }

      categoriesOptions.push(mapOption(option));
    });

    return categoriesOptions;
  }

  const mobileFilter = (
    <div className="flex w-full items-center">
      <DropdownInput
        id="bookCategories"
        className=""
        options={getMobileOptions()}
        placeholder="Select filters"
        selectedValues={selectedMobileFilters.map((name) => setCategory(name))}
        displayLimiter={1}
        multiple
        dropdownClasses="overflow-y-auto"
      />

      <Tooltip style="dark" content="Reset Filter">
        <Button className="ml-4 w-[36px] h-[36px]" type="muted" onClick={handleClearFilters}>
          <RestartIcon />
        </Button>
      </Tooltip>
    </div>
  );

  const desktopFilter = (
    <>
      {categories?.data?.map((category) => mapAsCheckbox(category))}

      <Tooltip style="dark" content="Reset Filter">
        <Button className="ml-4 w-[36px] h-[36px]" type="muted" onClick={handleClearFilters}>
          <RestartIcon />
        </Button>
      </Tooltip>
    </>
  );

  function setCategory(label) {
    const categoryMap = {
      'U.S. Books': (
        <div className="flex items-center gap-x-[4px] font-semibold">
          <img src={USFlag} height="12px" alt="US flag" /> US
        </div>
      ),
      'Canadian Books': (
        <div className="flex items-center gap-x-[4px] font-semibold">
          <img src={CanadaFlag} height={12} alt="Canada flag" /> Canada
        </div>
      ),
      'Spanish Books': <span className="font-semibold">Español</span>,
    };

    return categoryMap[label] || <span className="font-semibold">{label}</span>;
  }

  if (isSucceeded(categories.status)) {
    categoriesSubHeader = isMobile ? mobileFilter : desktopFilter;
  }

  if (booksList?.length > 0) {
    content = (
      <div className="mb-4 flex flex-wrap gap-y-4 gap-x-5 justify-around overflow-hidden">
        {booksList.map((book, index) => {
          const bookStatus = book?.links?.status;
          const currentBookPreview = bookPreviews.find((previews) => book._id === previews.bookId);

          if (
            book?.categoryDetails?.name === 'Dentist Guides' ||
            (!book.isAllowed && !currentBookPreview)
          ) {
            return null;
          }

          return (
            <div className="relative w-[216px]">
              <BookPreview
                book={book}
                currentBookPreview={currentBookPreview}
                linkUrl={getRouteDraftGenerateByBookId(book?._id)}
                key={book.name}
              />
              {(book?.milestone || (currentBookPreview && !book?.isAllowed)) && (
                <BookLabel
                  label={setBookLabel(bookStatus, book?.isAllowed, bookPreviews)}
                  className={setLabelColor(bookStatus)}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }

  const handleFilter = (e) => {
    const { value } = e.target;
    setSearchValue(value);

    const filteredBooks = books.data.filter(
      ({ name, title }) =>
        name?.toLowerCase().includes(value.toLowerCase()) ||
        title?.toLowerCase().includes(value.toLowerCase()),
    );

    setBooksList(filteredBooks);
  };

  if (isBooksLoading) {
    content = (
      <div
        role="status"
        className="mb-4 flex flex-wrap gap-y-4 gap-x-5 justify-center overflow-hidden animate-pulse"
      >
        {Array.from({ length: 40 }).map(() => (
          <div>
            <div className="flex items-center justify-center w-[216px] h-[328px] bg-gray-300 rounded dark:bg-gray-700" />
            <div className="h-4 mt-2 bg-gray-300 dark:bg-gray-700 w-full" />
            <div className="h-2.5 mt-1 bg-gray-300 dark:bg-gray-700 w-full" />
          </div>
        ))}
      </div>
    );
  }

  const hasBooksToShow = booksList?.length > 0;

  if (!hasBooksToShow && !isBooksLoading) {
    content = (
      <div className="flex flex-col items-center justify-center h-[400px]">
        <img src={empty} alt="No books found" className="mb-3.5" />
        <p className="font-semibold">No results found for “{searchValue}”</p>
        <p className="text-sm font-medium">
          Try a different keyword or{' '}
          <button
            type="button"
            onClick={handleClearFilters}
            className="text-primary-500 text-sm cursor-pointer"
          >
            <u>reset filter</u>
          </button>
          .
        </p>
      </div>
    );
  }

  return (
    <DashboardTemplate
      title="Book catalog"
      appendSubHeader
      subHeader={(
        <S.CreditsWrapper>
          {creditsSubHeader}
          {showBuyCreditsButton && (
            <Button size="base" color="primary" onClick={() => history.push(ROUTE_BUY_CREDITS)}>
              Buy credits
            </Button>
          )}
        </S.CreditsWrapper>
      )}
    >
      <div className="w-full">
        <div className="w-full flex justify-between items-center bg-neutral-0 py-[15px] px-6 rounded-t-lg border-b border-neutral-200 shadow-box max-[650px]:flex-col gap-2">
          <div className={`max-w-full w-[550px] ${isMobile ? 'mb-2': ''}`}>
            <TextInput
              onChange={handleFilter}
              placeholder="Search title or category"
              sizing="md"
              errorMessage="Invalid input"
              value={searchValue}
              className="w-full"
              color="gray"
            />
          </div>
          <div className="max-w-full w-[550px] flex justify-end max-[650px]:justify-center max-[420px]:flex-wrap gap-2">
            {categoriesSubHeader}
          </div>
        </div>
        <div className="bg-neutral-0 py-[15px] px-6 rounded-b-lg shadow-box">{content}</div>
      </div>
      <PaymentSteps
        setShowPaymentSteps={setShowPaymentSteps}
        showPaymentSteps={showPaymentSteps}
        getUserCredits={reactCreditQueryRefetch}
      />
    </DashboardTemplate>
  );
};

export default compose(withAuth, withUserCredits, withBooksStore, withReleaseNotes)(BookCatalog);
