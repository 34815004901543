import { TextInput } from 'flowbite-react';
import { defaultTextInputTheme } from 'modules/v2/common/AtomicDesign/atoms/TextInput/theme';
import get from 'lodash/get';
import S from '../../styles';

const QRCode = ({ formikHandler, step = '' }) => {
  const { values = {}, setFieldValue } = formikHandler || {};

  const targetLocation = step.concat('.', '_formFields');

  const handleChange = (e) => {
    const {
      target: { name = '', value = '' },
    } = e || {};
    const locationToSave = step.concat('.', '_formFields', '.', name);
    setFieldValue(locationToSave, value);
  };
  return (
    <>
      <div className="flex gap-5">
        <S.InputWrapWithMarginTop marginTop={5}>
          <label className="text-neutral-800 text-sm font-semibold">
            Scanning the QR code will show this information
          </label>
          <TextInput
            theme={defaultTextInputTheme}
            id="qrCode"
            name="qrCode"
            type="text"
            value={get(values, `${targetLocation}.qrCode`)}
            onChange={handleChange}
            placeholder="Type any link, email, website, phone number, etc."
          />
        </S.InputWrapWithMarginTop>
      </div>
    </>
  );
};

export default QRCode;
