import { useMutation } from 'react-query';
import { sign } from '../../../../api/digitalServices/prosper-stack-signature';

export const useSignature = (config?) => {
  const mutation = useMutation(sign, config);
  return {
    ...mutation,
    generateSignature: mutation.mutateAsync,
  };
};
