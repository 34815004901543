import { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { get, startCase, toLower } from 'lodash';
import { Skeleton } from 'antd';
import { getRouteDraftGenerateByBookId } from 'modules/v2/draft/routes/navigation';
import { fetchAllBooks } from 'modules/api';
import { Title, Button } from 'modules/v2/common/AtomicDesign/atoms';
import { FrontArrowIcon } from 'modules/v2/common/components/SvgIcons';
import { BookLabel } from 'modules/v2/pages/BookCatalog/components';
import { setBookLabel, setLabelColor } from 'modules/v2/pages/BookCatalog/utils';
import { getRouteBookCatalog } from 'modules/v2/routes/navigation';

import { formatBookList } from './utils';

const BooksContainer = (props) => {
  const [bookList, setBookList] = useState([]);
  const history = useHistory();

  const { isFetching: isListLoading, refetch } = useQuery(
    'fetchAllBooks',
    () => fetchAllBooks({ limit: 3, isAllowed: true }),
    {
      onSuccess: (data) => {
        const bookListData = (get(data, 'data.data.books') || get(data, 'data.books') || [])
          // NOTE: `null` appeared in books list, filter out falsey values
          .filter((book) => Boolean(book));

        const formattedBookList = formatBookList(bookListData);

        setBookList(formattedBookList);
      },
    },
  );

  const handleSeeAllBooks = () => {
    history.push(getRouteBookCatalog());
  };

  return (
    <div className="flex flex-col">
      <Title className="mb-3.5">My books</Title>
      <div className="flex flex-col">
        <div className="flex flex-column justify-start h-[280px] gap-2 clear-right w-full flex-wrap max-sm:justify-center max-lg:h-fit overflow-hidden">
          {isListLoading ? (
            <>
              <div className="h-[280px] flex-1">
                <Skeleton loading active paragraph={{ rows: 6 }} />
              </div>
              <div className="h-[280px] flex-1">
                <Skeleton loading active paragraph={{ rows: 6 }} />
              </div>
              <div className="h-[280px] flex-1">
                <Skeleton loading active paragraph={{ rows: 6 }} />
              </div>
            </>
          ) : null}

          {!isListLoading &&
            bookList.map((book) => {
              const hasDraft = book?.title;
              return (
                <div key={book.id} className="w-[148px]">
                  <Link to={getRouteDraftGenerateByBookId(book.id)} className="relative block">
                    <div className="rounded-md overflow-hidden">
                      <img
                        src={`${book.imageUrl}?random=33`}
                        height="280px" // setting height directly to avoid CLS
                        className="w-[148px] h-[224px] rounded-md object-cover hover:transform hover:scale-110 transition-transform duration-300 ease-in-out"
                        alt={book.title}
                      />
                    </div>

                    {hasDraft && (
                      <BookLabel
                        label={setBookLabel(book?.status, book?.isAllowed)}
                        className={setLabelColor(book?.status)}
                      />
                    )}

                    <h3 className="text-sm font-medium mt-2 mb-0 pb-0 truncate tracking-normal">
                      {startCase(toLower(book.title))}
                    </h3>
                    <div className="text-sm text-neutral-500 font-medium truncate">{book.name}</div>
                  </Link>
                </div>
              );
            })}
        </div>
        <div className="relative mt-3">
          <Button size="xs" color="outlined" onClick={handleSeeAllBooks}>
            <div className="flex items-center justify-center">
              See all book catalog <FrontArrowIcon className="ml-2" />
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BooksContainer;
