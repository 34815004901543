import { ReactNode } from 'react';

interface SuggestionCardProps {
  description: string;
  icon: ReactNode;
  className: string;
}

const SuggestionCard = ({ description, icon, className }: SuggestionCardProps) => {
  return (
    <div className={`flex space-x-3 px-3 py-2.5 rounded-md ${className || 'bg-neutral-100'}`}>
      <div className="w-5 h-5">{icon}</div>
      <div>{description}</div>
    </div>
  );
};

export default SuggestionCard;
