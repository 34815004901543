import env from 'environments';

import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { get } from 'lodash-es';

import { getAvailableTimeSlots, getTrackingPixels, scheduleCoachingCall } from 'modules/api';

import { AlertWithButton, Loader } from 'modules/common/components';

import {
  getCurrentTimeZone,
  getCurrentTimeZoneWithDetachment,
  notification,
} from 'modules/common/utils';

import { AuthorifyIcon } from 'modules/common/components/SvgIcon';
import { setItem } from 'modules/dashboard/utils/legacy';
import { isDentistOffer } from 'modules/dashboard/utils';
import { useFetchSession, useStep } from 'modules/onboard/hooks';
import { OnboardTemplate } from 'modules/onboard/templates';
import { ConfirmComponent } from 'modules/onboard/components';

import { addShippingAddress } from 'modules/api/onboardShippingAddress';
import useHubspotTrackCode from '../Common/HubspotTrackCode';
import { DATE_FORMAT } from './constants';
import { getFirstAvailableDay, parseSlots } from './utils';
import S from './styles';

const ScheduleCoachingPage = () => {
  const { goToNextStep } = useStep();
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const { session, refetchSession } = useFetchSession();
  useHubspotTrackCode();
  const currentStartDay = getFirstAvailableDay();
  const formattedCurrentStartDay = currentStartDay.toFormat(DATE_FORMAT);
  const [busySlotSelection, setBusySlotSelection] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const queryClient = useQueryClient();
  const isDentist = isDentistOffer();

  const [storedFirstName, setStoredFirstName] = useState(session?.customer?.firstName);
  const [storedLastName, setStoredLastName] = useState(session?.customer?.lastName);
  const [storedPhone, setStoredPhone] = useState(session?.customer?.phone);
  const [storedEmail, setStoredEmail] = useState(session?.customer?.email);
  const [meetingUrl, setMeetingUrl] = useState('');

  const appendHubspotWidgetScript = () => {
    const script = document.createElement('script');
    script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    script.type = 'text/javascript';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  };

  useEffect(() => {
    const offerCode = session?.currentOffer?.code ?? '';
    const isMortgage = offerCode.includes('mortgage');
    setMeetingUrl(
      `${
        isMortgage ? env.MEETING_TOOL_MORTGAGE_URL : env.MEETING_TOOL_RMM_URL
      }&email=${encodeURIComponent(storedEmail)}&firstname=${encodeURIComponent(
        storedFirstName,
      )}&lastname=${encodeURIComponent(storedLastName)}&phone=${encodeURIComponent(storedPhone)}`,
    );
    const cleanup = appendHubspotWidgetScript();
    return () => {
      cleanup();
    };
  }, [session, storedEmail, storedFirstName, storedLastName, storedPhone]);

  const { mutateAsync: mutateShippingAddress } = useMutation(addShippingAddress, {
    onSuccess: () => {
      localStorage.removeItem('onboard-shipping-address');
    },
  });

  const {
    isError: isGetTimeSlotsError,
    isLoading: isGetTimeSlotsLoading,
    isSuccess: isGetTimeSlotsSuccess,
  } = useQuery(
    ['getTimeSlots', formattedCurrentStartDay],
    () => {
      const start = currentStartDay.toISO();
      const daysToAdd = env.MAX_TIME_FRAME_FOR_COACH_SCHEDULER;
      let end = currentStartDay.plus({ days: daysToAdd });

      while (end.weekday === 6 || end.weekday === 7) {
        end = end.plus({ days: 1 });
      }

      return getAvailableTimeSlots({
        start,
        end: end.toISO(),
      });
    },
    {
      enabled: !isDentist,
      onSuccess: ({ dates }) => {
        const slots = parseSlots(dates);

        setTimeSlots(slots);
      },
    },
  );

  const {
    isError: isGetTimeSlotsForDentistError,
    isLoading: isGetTimeSlotsForDentistLoading,
    isSuccess: isGetTimeSlotsForDentistSuccess,
  } = useQuery(
    ['getTimeSlotsForDentist', formattedCurrentStartDay],
    () => {
      const start = currentStartDay.toISO();
      const daysToAdd = env.MAX_TIME_FRAME_FOR_COACH_SCHEDULER;
      let end = currentStartDay.plus({ days: daysToAdd });

      while (end.weekday === 6 || end.weekday === 7) {
        end = end.plus({ days: 1 });
      }

      return getAvailableTimeSlots({
        start,
        end: end.toISO(),
      });
    },
    {
      enabled: isDentist,
      onSuccess: ({ dates }) => {
        const slots = parseSlots(dates);

        setTimeSlots(slots);
      },
    },
  );

  const { data: trackingList, isLoading: isTrackingListLoading } = useQuery(
    ['getTrackingPixelsList'],
    () =>
      getTrackingPixels({
        offerCode: session.offer.code,
      }),
  );

  if (isGetTimeSlotsError || isGetTimeSlotsForDentistError) {
    notification.error({
      title: 'Error',
      description:
        'It was not possible to get the list of timeSlots available. Please refresh the page to try again.',
    });
  }

  const { isLoading, isSuccess, mutateAsync: mutateSelectSlot } = useMutation(
    scheduleCoachingCall,
    {
      onSuccess: async () => {
        const sessionResponse = await refetchSession();
        const newStep = sessionResponse?.data?.data?.step;

        try {
          const shippingAddress = JSON.parse(localStorage.getItem('onboard-shipping-address'));
          await mutateShippingAddress(shippingAddress);
        } catch (error) {
          console.error('Error adding shipping address:', error);
        }

        goToNextStep(newStep);
      },
      onError: ({ response }) => {
        const { statusCode, error } = get(response, 'data', {});
        const isBusySlot = statusCode === 400 && error === 'SchedulingOnBusySlotException';

        // refresh the available slots list
        queryClient.invalidateQueries(['getTimeSlots', formattedCurrentStartDay]);

        if (isBusySlot) {
          return setBusySlotSelection(isBusySlot);
        }

        return setIsErrored(true);
      },
    },
  );

  const formattedTimezone = getCurrentTimeZoneWithDetachment();

  const handleClick = async () => {
    if (selectedSlot) {
      setItem('selectedSlot', true);

      const payload = {
        ...selectedSlot,
        title: `${session?.customer?.firstName}, your Authorify coaching call has been confirmed!`,
        description:
          "Congratulations! You are scheduled for an introduction call to learn how to grow your business with a unique branding strategy. You'll just need to click on the zoom link from this invite and be in front of your computer for the call. I'll be sharing my screen for part of the session reviewing some strategies we have for Authorify members and how we can help you scale your business.I'm excited to learn more about you and how Authorify can help grow your business.",
        sessionId: session?.id,
        timezone: getCurrentTimeZone,
        offerCode: session?.offer?.code,
        attendees: [
          {
            name: session?.customer?.firstName,
            email: session?.customer?.email,
          },
        ],
      };

      await mutateSelectSlot(payload);
    }
  };

  if (isLoading) {
    return (
      <OnboardTemplate currentStep="2" isDentist={isDentist}>
        <S.ResultWrap>
          <Loader
            height="100%"
            hasIcon
            title="Confirming Your Schedule"
            description="Please wait while we are scheduling your introduction call."
            icon={<AuthorifyIcon />}
          />
        </S.ResultWrap>
      </OnboardTemplate>
    );
  }

  if (isSuccess) {
    return (
      <OnboardTemplate currentStep="2" isDentist={isDentist}>
        <S.ResultWrap>
          <ConfirmComponent
            heading="Schedule an Introduction Call Confirmed"
            subHeading="An invitation will be send to your email address."
            iconSize={40}
            imgWidth={35}
          />
        </S.ResultWrap>
      </OnboardTemplate>
    );
  }

  const handleCloseError = () => setBusySlotSelection(false);

  if (busySlotSelection) {
    return (
      <OnboardTemplate currentStep="2" isDentist={isDentist}>
        <S.ResultWrap>
          <AlertWithButton
            description="Sorry, you just missed it. Someone already booked this schedule. Please select a different time or date."
            buttonTitle="Select New Schedule"
            width="500px"
            handleClick={handleCloseError}
          />
        </S.ResultWrap>
      </OnboardTemplate>
    );
  }

  if (isErrored) {
    return (
      <OnboardTemplate currentStep="2" isDentist={isDentist}>
        <S.ResultWrap>
          <AlertWithButton
            type="info"
            buttonTitle="Try again"
            description="Sorry, there was an error. Please try again later or try again."
            width={500}
            handleClick={() => setIsErrored(false)}
          />
        </S.ResultWrap>
      </OnboardTemplate>
    );
  }

  return (
    <OnboardTemplate
      currentStep="2"
      isLoading={isGetTimeSlotsLoading || isGetTimeSlotsForDentistLoading}
      isDentist={isDentist}
    >
      {!isTrackingListLoading &&
        trackingList.map((items) => (
          <img src={items.trackingCode} width="0" height="0" key={items.id} alt={items.offerCode} />
        ))}
      <S.ScheduleHead data-cy="schedule-coaching-title">
        Schedule your Consultation Call
      </S.ScheduleHead>

      <div className="w-full">
        <div className="meetings-iframe-container" data-src={meetingUrl}></div>
      </div>
    </OnboardTemplate>
  );
};

export default ScheduleCoachingPage;
