import React from 'react';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import env from 'environments';
import flow from '@prosperstack/flow';
import { useHistory } from 'react-router-dom';
import { useSignature } from '../hooks/use-signature';

const CANCELED = 'canceled';

const CancelMembershipButton = ({ subscription }) => {
  const clientId = env.VITE_CLIENT_ID;
  const { generateSignature, isLoading } = useSignature();

  const history = useHistory();

  const handleCancelMembership = async () => {
    const payload = {
      clientId,
      subscription: {
        platformId: subscription.id.toString(),
      },
    };

    try {
      const signature = await generateSignature(payload);
      flow(
        {
          payload: JSON.stringify(payload),
          signature,
        },
        {
          onCompleted: async () => {
            history.push('/');
          },
        },
      );
    } catch (error) {
      console.error('Error while canceling membership:', error);
    }
  };

  const isSubscriptionLoading =
    !subscription?.id || !subscription?.state || subscription?.state === '';

  const isSubscriptionCanceled = subscription?.state === CANCELED;

  if (isSubscriptionCanceled) {
    return null;
  }

  return (
    <>
      {isSubscriptionLoading ? (
        <div role="status" className="animate-pulse inline-block">
          <div className="w-32 h-10 bg-gray-200 rounded" />
        </div>
      ) : (
        <Button type="primary" onClick={handleCancelMembership} className="" loading={isLoading}>
          Cancel My Membership
        </Button>
      )}
    </>
  );
};

export default CancelMembershipButton;
