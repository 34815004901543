export const ROUTE_HOMEPAGE = '/';
export const ROUTE_BILLING_INFO = '/?data=:data';
export const MY_BOOKS = '/my-books';
export const ROUTE_SOCIAL_MEDIA_TRAINING_SITE = '/training/site';
export const ROUTE_TRAINING_SITE = '/trainings/site';
export const DASHBOARD = '/dashboard';
export const REFERRAL_MARKETING = '/referral-marketing';

// Welcome Journey
export const ROUTE_WELCOME_JOURNEY = '/welcome-journey';

// My Books pages
export const ROUTE_BOOK_CATALOG = `${MY_BOOKS}/book-catalog`;
export const ROUTE_MY_ORDERS = `${MY_BOOKS}/my-orders`;
export const ROUTE_DIGITAL_BOOKS = `${MY_BOOKS}/digital-books`;
export const ROUTE_BOOK_PERFORMANCE = `${MY_BOOKS}/book-performance`;
export const ROUTE_MANAGE_BOOK_VERSION = `${MY_BOOKS}/manage-book-version`;
export const ROUTE_CHECKOUT = `${MY_BOOKS}/checkout`;

// BUY CREDITS
export const ROUTE_BUY_CREDITS = `${MY_BOOKS}/buy-credits`;

// Campaigns pages
export const ROUTE_DASHBOARD = `${DASHBOARD}`;
export const ROUTE_BILLING = '/billing';
export const ROUTE_PLANS = '/plans';
export const ROUTE_PAYMENTS = '/payments';
export const EMAIL_CAMPAIGN = `${DASHBOARD}/email-campaign`;
export const CAMPAIGNS = `${EMAIL_CAMPAIGN}/campaigns`;
export const ROUTE_UPGRADE_PLAN = `${ROUTE_PLANS}/upgrade`;
export const ROUTE_PLAN_CONFIRMATION = `${ROUTE_PLANS}/confirmation`;
export const ROUTE_LEADS = '/leads';
export const ROUTE_IMPORT_LEADS = `${ROUTE_LEADS}/import`;

// Email Campaign pages
export const ROUTE_EMAIL_SENDER_EMAIL_CONFIGURATION = `${EMAIL_CAMPAIGN}/sender-email-configuration`;
export const ROUTE_EMAIL_HISTORY = `${DASHBOARD}/email-history/:leadId/:origin`;
export const ROUTE_EMAIL_TEMPLATES = `${EMAIL_CAMPAIGN}/templates`;
export const ROUTE_EMAIL_CAMPAIGNS_ANALYTICS = `${EMAIL_CAMPAIGN}/campaign-analytics`;
export const ROUTE_EMAIL_LAUNCH_CAMPAIGN = `${CAMPAIGNS}/launch-campaign`;
export const ROUTE_CREATE_EMAIL_CAMPAIGN = `${CAMPAIGNS}/:content/create`;
export const ROUTE_CAMPAIGNS_HISTORY = `${ROUTE_EMAIL_LAUNCH_CAMPAIGN}/:id/history`;
export const ROUTE_ALL_CAMPAIGN_METRICS = `${CAMPAIGNS}/campaign-metrics`;
export const ROUTE_CREATE_ON_DEMAND_EMAIL = `${CAMPAIGNS}/on-demand-email`;
export const ROUTE_ON_DEMAND_EMAIL = `${CAMPAIGNS}/on-demand-emails/:id`;
export const ROUTE_EMAIL_CAMPAIGN = `${CAMPAIGNS}/:id`;

export const ROUTE_HELP_CENTER = '/help-center';
export const ROUTE_LOGIN = '/auth/login';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_AUTO_LOGIN = '/auth/token';
export const ROUTE_RELEASE_NOTES = '/release-notes';
export const ROUTE_RESET_PASSWORD = '/auth/reset';
export const ROUTE_NEW_PASSWORD = '/auth/newpassword';

export const ROUTE_CHANGE_PASSWORD = `${ROUTE_PROFILE}/password`;
export const ROUTE_PROFILE_PLANS = `${ROUTE_PROFILE}/plans`;
export const ROUTE_PAGE_PROFILE = `${ROUTE_PROFILE}/page-profile`;
export const ROUTE_EDIT_SHIPPING_ADDRESS = `${ROUTE_PROFILE}/shipping-address`;

// Referral Marketing pages
export const ROUTE_REFERRAL_MARKETING_REDIRECT_ROUTE = `${REFERRAL_MARKETING}/my-magazine`;
export const ROUTE_REFERRAL_MARKETING_MAGAZINE_EDITOR = `${REFERRAL_MARKETING}/magazine-editor/:month/:year`;
export const ROUTE_REFERRAL_MARKETING_GENERATED_MAGAZINE = `${REFERRAL_MARKETING}/generated-magazine`;
export const ROUTE_REFERRAL_MARKETING_PREVIOUS_MAGAZINES = `${REFERRAL_MARKETING}/previous-magazines`;
export const ROUTE_REFERRAL_MARKETING_MAGAZINE_RECIPIENTS = `${REFERRAL_MARKETING}/magazine-recipients`;

export const ROUTE_CREATE_CUSTOMER_TEMPLATE = `${ROUTE_EMAIL_TEMPLATES}/create/:templateBaseId?`;
export const ROUTE_EDIT_CUSTOMER_TEMPLATE = `${ROUTE_EMAIL_TEMPLATES}/edit/:id`;

export const ROUTE_UNSUBSCRIBE = '/unsubscribe/:id';

export const ROUTE_PLANS_AND_BILLING = '/plans-and-billing';

export const ROUTE_PLAN_CANCEL = `${ROUTE_PLANS_AND_BILLING}/cancel`;
export const ROUTE_PLAN_UPGRADE = `${ROUTE_PLANS_AND_BILLING}/upgrade`;
