import { getInstance } from './instance';
import { ProsperStackPayloadDto } from './dtos/prosper-stack-signature.dto';

const instance = getInstance();
const PATH = '/signature';


export const sign = async (payload: ProsperStackPayloadDto) => {
  try {
    const response = await instance.post(`${PATH}/sign`, payload);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

