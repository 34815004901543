import React from 'react';
import { Card } from 'flowbite-react';

interface Props {
  title: string;
  amount: number;
  Icon: any;
}
export const Benefits = ({ title, amount, Icon }: Props) => {
  return (
    <Card
      key={title}
      theme={{
        root: {
          base:
            'bg-white flex rounded-lg border-2 border-gray-200 hover:border-primary-200 transition duration-300 ease-in-out group',
          children: 'h-[138px] w-[198px] justify-center gap-4 p-[24px]',
        },
      }}
    >
      <div className="flex flex-col group-hover:translate-y-[-4px] duration-200">
        {Icon}
        <span className="text-3xl font-extrabold">{amount}</span>
        <span className="text-sm leading-5 font-medium font-light">{title}</span>
      </div>
    </Card>
  );
};
