const defaultConfig = {
  service: {
    crop: {
      height: 240,
      weidth: 468,
    },
    authoreImageTypeAllowed: ['jpeg', 'jpg', 'png'],
    allowedUploadImageSize: {
      height: 500,
      weidth: 500,
    },
    buyCreditsContactInfo: {
      phoneNumber: '904-695-9933',
    },
  },
};

export const boolean = (value) => {
  const regex = /^(?:f(?:alse)?|no?|0+)$/i;
  return !regex.test(value) && !!value;
};

export default {
  ...defaultConfig,
  PUBLIC_URL: import.meta.env.VITE_PUBLIC_URL,
  REACT_APP_ENV: import.meta.env.VITE_APP_ENV,
  REACT_APP_NAME: import.meta.env.VITE_NAME,
  BASE_URL: import.meta.env.VITE_BASE_URL,
  BBA_URL: import.meta.env.VITE_BBA_URL,
  DIGITAL_SERVICES_URL: import.meta.env.VITE_DIGITAL_SERVICES_URL,
  SCHEDULER_SERVICES_URL: import.meta.env.VITE_SCHEDULER_SERVICES_URL,
  ADMIN_URL: import.meta.env.VITE_ADMIN_URL,
  BASE_AUTH_URL_STATIC_FILES: import.meta.env.VITE_BASE_AUTH_URL_STATIC_FILES,
  GRAPHQL_URL: import.meta.env.VITE_GRAPHQL_URL,
  REDIRECT_URL: import.meta.env.VITE_REDIRECT_URL,
  CREDITS_REDIRECT_URL: import.meta.env.VITE_CREDITS_REDIRECT_URL,
  SUPPORT_EMAIL: import.meta.env.VITE_SUPPORT_EMAIL,
  SUPPORT_PHONE: import.meta.env.VITE_SUPPORT_PHONE,
  SUPPORT_OPPENING_HOURS: import.meta.env.VITE_SUPPORT_OPPENING_HOURS,
  SELL_CREDITS_LINK_URL: import.meta.env.VITE_SELL_CREDITS_LINK_URL,
  SELL_CREDITS_LINK_LABEL: import.meta.env.VITE_SELL_CREDITS_LINK_LABEL,
  TERMS_URL: import.meta.env.VITE_TERMS_URL,
  UPDATE_BILLING_INFO: import.meta.env.VITE_UPDATE_BILLING_INFO,
  ADMIN_PREFIX: import.meta.env.VITE_ADMIN_PREFIX,
  ADMIN_ENABLED: boolean(import.meta.env.VITE_ADMIN_ENABLED),
  MASTER_ENABLED: boolean(import.meta.env.VITE_MASTER_ENABLED),
  LOGGER: boolean(import.meta.env.VITE_LOGGER),
  SOCIAL_TRAINING_URL: import.meta.env.VITE_TRAINING_URL,
  SOCIAL_MEDIA_TRAINING_URL: import.meta.env.VITE_SOCIAL_MEDIA_TRAINING_URL,
  HUB_URL: import.meta.env.VITE_HUB_URL,
  CAMPAIGNS_URL: import.meta.env.VITE_CAMPAIGNS_URL,
  DIGITAL_INTEGRATION_SERVICES_URL: import.meta.env.VITE_DIGITAL_INTEGRATION_SERVICES_URL,
  STRIPE_PUBLISHABLE_KEY: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
  PRIVACY_URL: import.meta.env.VITE_PRIVACY_URL,
  RMM_CURRENT_MONTH: import.meta.env.VITE_RMM_CURRENT_MONTH,
  HELP_CENTER_URL: import.meta.env.VITE_HELP_CENTER_URL,
  SCHEDULE_COACHING_DURATION: import.meta.env.VITE_SCHEDULE_COACHING_DURATION,
  DS_BUCKET: import.meta.env.VITE_DS_BUCKET,
  CHARGIFY_PUBLIC_KEY: import.meta.env.VITE_CHARGIFY_PUBLIC_KEY,
  CHARGIFY_SERVER_HOST: import.meta.env.VITE_CHARGIFY_SERVER_HOST,
  OFFBORADING_SERVICE_URL: import.meta.env.VITE_OFFBORADING_SERVICE_URL,
  ENVIRONMENT: import.meta.env.VITE_ENVIRONMENT,
  APP_VERSION: import.meta.env.VITE_APP_VERSION,
  DS_API_KEY: import.meta.env.VITE_DS_API_KEY,
  SOCKET_IO: import.meta.env.VITE_SOCKET_IO,
  PAYMENTS_URL: import.meta.env.VITE_PAYMENTS_URL,
  FEATURE_CLICK_FUNNELS: boolean(import.meta.env.VITE_FEATURE_CLICK_FUNNELS),
  FEATURE_ONBOARDING: boolean(import.meta.env.VITE_FEATURE_ONBOARDING),
  FEATURE_RM_ADMIN_EDIT: boolean(import.meta.env.VITE_FEATURE_RM_ADMIN_EDIT),
  FEATURE_LANDING_PAGES: boolean(import.meta.env.VITE_FEATURE_LANDING_PAGES),
  FEATURE_UPGRADE_PLANS: boolean(import.meta.env.VITE_FEATURE_UPGRADE_PLANS),
  FEATURE_PLUS_PLANS: boolean(import.meta.env.VITE_FEATURE_PLUS_PLANS),
  FEATURE_V2: boolean(import.meta.env.VITE_FEATURE_V2),
  CSR_MEETING_LINK: import.meta.env.VITE_CSR_MEETING_LINK,
  HS_PORTAL_ID: import.meta.env.VITE_HS_PORTAL_ID,
  HS_DIGITAL_PLUS_FORM_ID: import.meta.env.VITE_HS_DIGITAL_PLUS_FORM_ID,
  HS_PREMIUM_PLUS_FORM_ID: import.meta.env.VITE_HS_PREMIUM_PLUS_FORM_ID,
  LOGGER_URL: import.meta.env.VITE_LOGGER_URL,
  EMAILS_FOR_DENTIST_OFFER: import.meta.env.VITE_EMAILS_FOR_DENTIST_OFFER,
  ENABLE_HOLIDAY_SALE: boolean(import.meta.env.VITE_ENABLE_HOLIDAY_SALE),
  FEATURE_TRIPWIRE_REPORT: boolean(import.meta.env.VITE_FEATURE_TRIPWIRE_REPORT),
  DATADOG_CLIENT_TOKEN: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  DATADOG_APPLICATION_ID: import.meta.env.VITE_DATADOG_APPLICATION_ID,
  DATADOG_ENV: import.meta.env.VITE_DATADOG_ENV,
  DATADOG_SERVICE_NAME: import.meta.env.VITE_DATADOG_SERVICE_NAME,
  FEATURE_REALTOR_LOGO: boolean(import.meta.env.VITE_FEATURE_REALTOR_LOGO),
  PRICING_PLANS: import.meta.env.VITE_PRICING_PLANS,
  AFY_PLANS_URL: import.meta.env.VITE_AFY_PLANS_URL,
  MAX_TIME_FRAME_FOR_COACH_SCHEDULER: import.meta.env.VITE_MAX_TIME_FRAME_FOR_COACH_SCHEDULER,
  VITE_APP_RMM_CURRENT_MONTH: import.meta.env.VITE_APP_RMM_CURRENT_MONTH,
  GOOGLE_MAP_API_KEY: import.meta.env.VITE_GOOGLE_MAP_API_KEY,
  JOURNEY_API_URL: import.meta.env.VITE_JOURNEY_API_URL,
  AFY_NEW_LOGIN: boolean(import.meta.env.VITE_AFY_NEW_LOGIN),
  VITE_AUTH_URL: import.meta.env.VITE_AUTH_URL,
  VITE_MAGAZINE_RECIPIENTS_FEATURE: boolean(import.meta.env.VITE_MAGAZINE_RECIPIENTS_FEATURE),
  VITE_GUIDES_AUTOMATION: boolean(import.meta.env.VITE_GUIDES_AUTOMATION),
  VITE_GUIDES_MEMBER: boolean(import.meta.env.VITE_GUIDES_MEMBER),
  PRINTED_BOOKS_URL: import.meta.env.VITE_PRINTED_BOOKS_URL,
  VITE_MEETING_SCHEDULE_STEP_TOOL_URL: import.meta.env.VITE_MEETING_SCHEDULE_STEP_TOOL_URL,
  VITE_CLIENT_ID: import.meta.env.VITE_CLIENT_ID,
  MEETING_TOOL_MORTGAGE_URL: import.meta.env.VITE_MEETING_TOOL_MORTGAGE_URL,
  MEETING_TOOL_RMM_URL: import.meta.env.VITE_MEETING_TOOL_RMM_URL,
};
