export default function PassportIcon({ width = 16, height = 16 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 6.25076C6 5.69847 5.55228 5.25076 5 5.25076C4.44772 5.25076 4 5.69847 4 6.25076H6ZM22.5 7.50076V8.50076H23.5V7.50076H22.5ZM19.6464 2.90841L19.505 1.91846L19.6464 2.90841ZM6.14949 4.83654L6.00807 3.8466L6.00807 3.8466L6.14949 4.83654ZM12.5 22.7508C11.9477 22.7508 11.5 23.1985 11.5 23.7508C11.5 24.303 11.9477 24.7508 12.5 24.7508V22.7508ZM17.5 24.7508C18.0523 24.7508 18.5 24.303 18.5 23.7508C18.5 23.1985 18.0523 22.7508 17.5 22.7508V24.7508ZM24 11.2508V23.7508H26V11.2508H24ZM21.25 26.5008H8.75V28.5008H21.25V26.5008ZM6 23.7508V7.50076H4V23.7508H6ZM6 7.50076V6.25076H4V7.50076H6ZM5 8.50076H21.25V6.50076H5V8.50076ZM8.75 26.5008C7.23122 26.5008 6 25.2695 6 23.7508H4C4 26.3741 6.12665 28.5008 8.75 28.5008V26.5008ZM24 23.7508C24 25.2695 22.7688 26.5008 21.25 26.5008V28.5008C23.8734 28.5008 26 26.3741 26 23.7508H24ZM26 11.2508C26 8.62741 23.8734 6.50076 21.25 6.50076V8.50076C22.7688 8.50076 24 9.73197 24 11.2508H26ZM17.75 16.2507C17.75 17.7695 16.5188 19.0007 15 19.0007V21.0007C17.6234 21.0007 19.75 18.8741 19.75 16.2507H17.75ZM15 19.0007C13.4812 19.0007 12.25 17.7695 12.25 16.2507H10.25C10.25 18.8741 12.3766 21.0007 15 21.0007V19.0007ZM12.25 16.2507C12.25 14.7319 13.4812 13.5007 15 13.5007V11.5007C12.3766 11.5007 10.25 13.6274 10.25 16.2507H12.25ZM15 13.5007C16.5188 13.5007 17.75 14.7319 17.75 16.2507H19.75C19.75 13.6274 17.6234 11.5007 15 11.5007V13.5007ZM6.33883 8.50076H22.5V6.50076H6.33883V8.50076ZM23.5 7.50076V5.38328H21.5V7.50076H23.5ZM19.505 1.91846L6.00807 3.8466L6.29091 5.82649L19.7879 3.89836L19.505 1.91846ZM6.00807 3.8466C4.85585 4.0112 4 4.998 4 6.16192H6C6 5.9933 6.12399 5.85034 6.29091 5.82649L6.00807 3.8466ZM23.5 5.38328C23.5 3.25336 21.6135 1.61724 19.505 1.91846L19.7879 3.89836C20.6915 3.76927 21.5 4.47046 21.5 5.38328H23.5ZM6.33883 6.50076C6.1517 6.50076 6 6.34906 6 6.16192H4C4 7.45363 5.04713 8.50076 6.33883 8.50076V6.50076ZM12.5 24.7508H17.5V22.7508H12.5V24.7508Z"
        fill="#4CC9E1"
      />
    </svg>
  );
}
