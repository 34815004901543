import { getItem } from 'modules/dashboard/utils/legacy';
import { getInstance } from './instance';

const instance = getInstance();

export const getAllCustomers = (page, perPage) => {
  const url = '/customers/find-all';
  const params = { page, perPage };

  return instance
    .get(url, { params })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getCustomer = () => {
  const url = '/customers';

  return instance
    .get(url)
    .then((response) => response.data)
    .catch(Promise.reject);
};

export const updateCustomer = (body) => {
  const url = '/customers';
  const token = getItem('token');

  instance.defaults.headers.common.Authorization = `Bearer ${token}`;

  return instance
    .patch(url, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const updateUserAvatar = ({ email, avatar }) => {
  const url = '/customers/avatar';
  const body = { email, avatar };

  return instance.patch(url, body);
};

export const getStripePaymentMethods = () => {
  return instance
    .get('/payments/customer/payment-methods')
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const getCustomerCreditsHistory = () => {
  return instance
    .get('/customers/info/credit-history')
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};
