import axios from 'axios';
import env from 'environments';

const afyPlansInstance = axios.create({
  baseURL: env.AFY_PLANS_URL,
});

const getCurrent = async ({ planHandle, componentHandle }) => {
  if (!planHandle) {
    return null;
  }
  try {
    const response = await afyPlansInstance.get('/plans/current', {
      params: { planHandle, componentHandle },
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

const upgrade = ({ chargifyId, componentHandle, productHandle }) => {
  return afyPlansInstance.post('/subscriptions/upgrade', {
    chargifyId,
    componentHandle,
    productHandle,
  });
};

const createQuote = ({ chargifyCustomerId, chargifyComponentHandle }) => {
  return afyPlansInstance.post('/hubspot/quotes', {
    chargifyCustomerId,
    chargifyComponentHandle,
  });
};

export { getCurrent, upgrade, createQuote };
