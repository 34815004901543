import React from 'react';
import { useQuery } from 'react-query';

import {
  fetchUserCredits,
  getAllLeads,
  getTotalEmailsSent,
  totalDigitalBooks,
  totalPrintedBooks,
} from 'modules/api';
import { Title } from 'modules/v2/common/AtomicDesign/atoms';

import {
  BoxIcon,
  DatabaseIcon,
  LetterIcon,
  NotebookIcon,
  PassportIcon,
  UserIcon,
  WindowFrameIcon,
} from 'modules/v2/common/components/SvgIcon';

import { Benefits } from './Benefits';

export default function RemainingBenefits({ cancelStep }) {
  const isProceedingOnCancel = () => cancelStep === 2;
  const isLastTry = () => cancelStep >= 3;

  const { data: userBookCredit } = useQuery(['getUserCredits'], fetchUserCredits);
  const bookCredits = userBookCredit?.data?.data?.credits || 0;

  const { data: userLeads } = useQuery(['getAllLeads'], getAllLeads);
  const userLeadsCount = userLeads?.data?.meta?.total || 0;

  const { data: totalEmailSent } = useQuery(['getTotalEmailsSent'], getTotalEmailsSent);
  const totalEmailsSent = totalEmailSent || 0;

  const { data: digitalBooks } = useQuery(['totalDigitalBooks'], totalDigitalBooks);
  const digitalBooksCount = digitalBooks?.data?.data || 0;

  const { data: printedBooks } = useQuery(['totalPrintedBooks'], totalPrintedBooks);
  const printedBooksCount = printedBooks?.data?.data?.totalQuantity || 0;

  // TODO: add remaining icons
  const benefits = [
    {
      text: 'Unused Credits',
      component: (idx: number) => (
        <Benefits
          key={idx}
          title="Unused Credits"
          amount={bookCredits}
          Icon={<span className="ml-[-4px]"><DatabaseIcon width={32} height={32} /></span>}
        />
      ),
    },
    {
      text: 'Leads Collected',
      component: (idx: number) => (
        <Benefits
          key={idx}
          title="Leads Collected"
          amount={userLeadsCount}
          Icon={<span className="ml-[-4px]"><UserIcon width={32} height={32} /></span>}
        />
      ),
    },
    {
      text: 'Books Printed',
      component: (idx: number) => (
        <Benefits
          key={idx}
          title="Books Printed"
          amount={printedBooksCount}
          Icon={<span className="ml-[-4px]"><PassportIcon width={32} height={32} /></span>}
        />
      ),
    },
    {
      text: 'Digital Books',
      component: (idx: number) => (
        <Benefits
          key={idx}
          title="Digital Books"
          amount={digitalBooksCount}
          Icon={<span className="ml-[-4px]"><NotebookIcon width={32} height={32} /></span>}
        />
      ),
    },
    {
      text: 'Lead Capture Sites',
      component: (idx: number) => (
        <Benefits
          key={idx}
          title="Lead Capture Sites"
          amount={digitalBooksCount}
          Icon={<span className="ml-[-4px]"><WindowFrameIcon fill="#4CC9E1" width={32} height={32} /></span>}
        />
      ),
    },
    {
      text: 'Emails Sent',
      component: (idx: number) => (
        <Benefits
          key={idx}
          title="Emails Sent"
          amount={totalEmailsSent}
          Icon={<span className="ml-[-4px]"><LetterIcon width={32} height={32} /></span>}
        />
      ),
    },
  ];

  return (
    <>
      <Title className="text-xl leading-7 font-semibold" size="medium">
        {isLastTry()
          ? "We're sorry to see you go..."
          : "Look at how much you've accomplished already!"}
      </Title>
      <span className="text-xl leading-7 font-bold" size="medium">
        You have {bookCredits} credits to use
      </span>

      <div className="flex flex-wrap justify-center gap-4 mt-4">
        {benefits.map(({ component }, idx) => component(idx))}
      </div>

      <div className="flex flex-col items-center my-6">
        <span className="text-xl leading-7 text-danger-500 font-bold" size="medium">
          {isLastTry() ? 'Are you sure you want to quit?' : "Don't lose your remaining credits!"}
        </span>

        <span>
          {isLastTry() ? (
            'We’re preparing to delete your edited books, remaining credits, lead capture sites, and everything else in your account.'
          ) : (
            <>
              Canceling your membership will <strong>DELETE</strong> your custom-edited books,
              remaining credits, lead capture sites, and everything else in your account. If you'd
              prefer to keep everything saved, consider pausing or switching your membership
              instead.
            </>
          )}
        </span>
      </div>

      {isProceedingOnCancel() && (
        <hr className="w-full h-px bg-gray-200 border-0 dark:bg-gray-700 mb-6" />
      )}

      {isLastTry() && (
        <div className="inline-flex items-center justify-center w-full mb-6">
          <hr className="w-full h-px bg-gray-200 border-0" />
          <span className="absolute px-3 font-medium text-gray-400 -translate-x-1/2 bg-white left-1/2">
            or
          </span>
        </div>
      )}
    </>
  );
}
