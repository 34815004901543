import { useEffect, useState } from 'react';
import axios from 'axios';
import env from 'environments';

/**
 * Hook to determine if a user has used email campaigns
 * @returns {boolean} Whether the user has used email campaigns
 */
const useEmailCampaignStatus = (): boolean => {
  const [hasUsedEmailCampaigns, setHasUsedEmailCampaigns] = useState(false);

  useEffect(() => {
    const fetchEmailVerificationStatus = async () => {
      try {
        const response = await axios.get(
          `${env.DIGITAL_SERVICES_URL}email-campaigns/attributes/has-verified-email`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );

        setHasUsedEmailCampaigns(response.data === true);
      } catch (error) {
        console.error('Error fetching email verification status:', error);
        setHasUsedEmailCampaigns(false);
      }
    };

    fetchEmailVerificationStatus();
  }, []);

  return hasUsedEmailCampaigns;
};

export default useEmailCampaignStatus;
