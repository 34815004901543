import classnames from 'classnames';

type ControlItemProps = {
  icon: React.ReactNode;
  label: string;
  type: 'danger' | 'neutral';
  active: boolean;
  onClick: () => void;
};

const ControlItem = ({ icon, label, type = 'neutral', active, onClick }: ControlItemProps) => {
  const listItemClasses = `
    px-2
    relative
    hover:after:w-2
    after:w-0
    after:content-[' ']
    after:absolute
    after:right-[-5px]
    after:top-[3px]
    after:h-[54px]
    after:bg-blueBorder
    after:transition-all
    after:duration-200
    after:rounded-full
    mb-2
    group
  `;

  const linkItemClasses = classnames(
    `
    items-center
    justify-center
    flex
    flex-col
    pt-[10px]
    pb-[8px]
    font-bold
    leading-4
    min-w-[84px]

    text-xs
    rounded-md
    ml-1

    hover:bg-neutral-100
    hover:text-neutral-800
  `,
    type === 'danger' ? 'text-error-500 hover:text-error-500' : 'text-neutral-800',
  );

  return (
    <li className={classnames(listItemClasses, active ? 'after:w-2' : '')}>
      <button
        type="button"
        className={classnames(linkItemClasses, active ? 'bg-neutral-100' : '')}
        onClick={onClick}
      >
        {!!icon && icon}

        <span className={type === 'danger' ? 'text-error-500 hover:text-error-500' : 'text-neutral-800'}>
          {label}
        </span>
      </button>
    </li>
  );
};

export default ControlItem;
