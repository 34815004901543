import { useEffect, useState } from 'react';
import { Tooltip } from 'flowbite-react';

import { cn } from 'modules/v2/common/utils';
import { useCustomerPlanInfo } from 'modules/v2/utils';
import { hasOnlyRMAccess } from 'modules/v2/utils/hasOnlyRMAccess';
import { useBookAccess } from 'modules/v2/utils/useBookAccess';
import { hideBookAccess } from 'modules/v2/utils/hideBookAccess';
import { isPilotCustomer } from 'modules/v2/utils/isPilotCustomer';
import { getData } from 'modules/auth';
import { useSelector, useDispatch } from 'react-redux';
import { updateIsBooks, updateIsRm } from 'shared/store/actions/bundleAccess';
import useEmailCampaignStatus from 'modules/v2/hooks/useEmailCampaignStatus';
import DropdownMenu from './DropdownMenu';
import navigationRoutes from './navigationRoutes';
import MenuItem from './MenuItem';
import {
  CAMPAIGNS,
  LEADS,
  MY_BOOKS,
  REFERRAL_MARKETING,
  TRAINING,
  WELCOME_JOURNEY,
} from './constants';

const SidebarMenu = ({ hasTrial = false, openMobile = false }) => {
  const dispatch = useDispatch();
  const { pathname } = window.location;
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [welcomeJourneyEligible, setWelcomeJourneyEligible] = useState(true);
  const hasUsedEmailCampaigns = useEmailCampaignStatus();
  const { hasBookAccess, isLoading } = useBookAccess();
  const { isRm, isBooks } = useSelector((data: any) => data.bundleAccess);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const { userData } = getData();
    if (userData?.welcomeJourneyEligible) {
      setWelcomeJourneyEligible(true);
    } else {
      setWelcomeJourneyEligible(false);
    }
  }, []);

  useEffect(() => {
    dispatch(updateIsBooks(hasBookAccess && !hideBookAccess()));
    dispatch(updateIsRm(isPilotCustomer() || hasOnlyRMAccess()));
  }, [hasBookAccess]);

  function shouldDisplayMenuItem(item: { key: string }) {
    return (
      (welcomeJourneyEligible !== false && item?.key === WELCOME_JOURNEY) ||
      (isRm && item?.key === REFERRAL_MARKETING) ||
      (isBooks && item?.key === MY_BOOKS) ||
      (item?.key === 'email-campaign' && hasUsedEmailCampaigns) ||
      ![MY_BOOKS, REFERRAL_MARKETING, WELCOME_JOURNEY, 'email-campaign'].includes(item?.key)
    );
  }

  const isMobile = windowSize < 1024;

  const isDigitalCustomer = useCustomerPlanInfo();
  const indexMyBooks = navigationRoutes.findIndex((item) => item.key === 'my-books');

  const booksNavigationUpdated = navigationRoutes[indexMyBooks].children.filter((child) => {
    if (!isDigitalCustomer.isDigital) {
      return child.key !== 'Digital-books';
    }
    return true;
  });

  const navigationRoutesUpdated = navigationRoutes.map((item) => {
    if (item.key === 'my-books') {
      return {
        ...item,
        children: booksNavigationUpdated,
      };
    }
    return item;
  });

  return openMobile ? (
    <div
      className={cn('pl-3 py-4 bg-white border-divider text-sm', {
        'fixed top-0 left-1 z-50 h-fit w-[calc(100%-8px)] border-[1px] shadow-box rounded-lg': isMobile,
        'h-full pb-20 overflow-y-auto border-r-[1px]': !isMobile,
      })}
    >
      <ul
        className={cn('font-medium space-y-2', {
          'mt-[96px]': (hasTrial || welcomeJourneyEligible) && isMobile,
          'mt-[44px]': (!hasTrial && !welcomeJourneyEligible) && isMobile,
        })}
      >
        {navigationRoutesUpdated.map((item) => (
          <>
            {shouldDisplayMenuItem(item) &&
              (() => {
                const isDisabled = welcomeJourneyEligible ? item.name !== 'Welcome journey' : false;

                return isDisabled ? (
                  <Tooltip
                    className={cn(
                      'ml-[100px] w-[200px]',
                      { 'ml-[70px]': item?.name === CAMPAIGNS },
                      { 'ml-[140px]': item?.name === LEADS },
                      { 'ml-[90px]': item?.name === TRAINING },
                    )}
                    content="Complete the action steps below to unlock this"
                    placement="right"
                  >
                    <li key={item.key} className="relative">
                      {!item.children ? (
                        <MenuItem
                          name={item?.name}
                          Icon={item?.icon}
                          href={item?.href}
                          current={pathname === item?.href}
                          welcomeJourneyEligible={welcomeJourneyEligible}
                        />
                      ) : (
                        <DropdownMenu
                          item={item}
                          open={pathname.includes(item.key)}
                          options={undefined}
                          current={pathname === item?.href}
                          welcomeJourneyEligible={welcomeJourneyEligible}
                        />
                      )}
                    </li>
                  </Tooltip>
                ) : (
                  <li key={item.key} className="relative">
                    {!item.children ? (
                      <MenuItem
                        name={item?.name}
                        Icon={item?.icon}
                        href={item?.href}
                        current={pathname === item?.href}
                        welcomeJourneyEligible={welcomeJourneyEligible}
                      />
                    ) : (
                      <DropdownMenu
                        item={item}
                        open={pathname.includes(item.key)}
                        options={undefined}
                        current={pathname === item?.href}
                        welcomeJourneyEligible={welcomeJourneyEligible}
                      />
                    )}
                  </li>
                );
              })()}
          </>
        ))}
      </ul>
    </div>
  ) : null;
};

export default SidebarMenu;
