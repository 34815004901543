import { pickBy } from 'lodash-es';
import getInstance from 'modules/api/instance';
import { getInstance as getDSInstance } from 'modules/api/digitalServices/instance';
import { setItem } from 'v2/utils/legacy';
import { getFileFromDataURI } from './utils';

const bbaInstance = getInstance();
const dsInstance = getDSInstance();

export const fetchUserCredits = () => {
  const url = '/user/credits';
  return bbaInstance.get(url);
};

export const fetchUserAddresses = () => {
  const url = '/user/address';
  return bbaInstance.get(url);
};

export const updateDefaultAddress = (_id) => {
  bbaInstance.post(`user/address/${_id}/set-default`);
};

export const upsertUserAddress = ({
  _id,
  firstName,
  lastName,
  addressLine1,
  addressLine2,
  phoneNumber,
  city,
  state,
  country,
  pincode,
  isDefault,
}) => {
  const url = '/user/address';
  const payload = {
    _id,
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    phoneNumber,
    city,
    state,
    country,
    pincode,
    isDefault,
  };

  if (payload.isDefault) {
    bbaInstance.post(`user/address/${payload._id}/set-default`);
  }
  return bbaInstance.post(url, payload);
};

export const deleteUserAddress = ({ addressId }) => {
  const url = '/user/address';
  const params = {
    shipping_address_id: addressId,
  };

  return bbaInstance.delete(url, { params });
};

export const uploadUserImage = ({ type, data, filename, imageFormat }) => {
  const url = '/user/image';
  const params = {
    type,
  };

  const formData = new FormData();

  const file = getFileFromDataURI(data, filename, imageFormat);
  formData.append('user_image', file);

  return bbaInstance.post(url, formData, { params });
};

export const uploadIntakeFormHeadshot = ({ data, filename }) => {
  const url = '/user/image/upload';
  const file = getFileFromDataURI(data, filename);
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', 'profile');

  return bbaInstance.post(url, formData);
};

export const fetchUserImages = (data) => {
  // to avoid reassigning to variable named 'type'
  const type = data.type === 'book_profile' ? 'profile' : data.type;
  const url = '/user/image';
  const params = {
    type,
  };
  return bbaInstance.get(url, { params });
};

export const updatePreferences = ({ name, email, phone, brokerInfo, headshotId }) => {
  const url = '/user/preferences';
  const params = pickBy(
    {
      name,
      email,
      phone,
      brokerInfo,
      headshotId,
    },
    (value) => Boolean(value),
  );

  return bbaInstance.post(url, params);
};

export const fetchProfile = () => {
  const url = '/user/profile';

  return bbaInstance.get(url);
};

export const updateProfile = ({ firstName, lastName, phone }) => {
  const url = '/user/profile';

  const params = {
    firstname: firstName,
    lastname: lastName,
    mobilephone: phone,
  };

  return bbaInstance.post(url, params);
};

export const uploadAvatar = ({ avatar }) => {
  const url = '/user/profile';
  const file = getFileFromDataURI(avatar);
  const formData = new FormData();

  formData.append('profile_image', file);

  return bbaInstance.post(url, formData);
};

export const updatePassword = ({ oldPassword, newPassword }) => {
  const url = '/auth/changepassword';

  const params = {
    old_password: oldPassword,
    new_password: newPassword,
  };

  return bbaInstance.post(url, params);
};

export const fetchBookLinks = () => {
  // TODO: needs to be updated when backend endpoint is ready
  const url = '/dashboard';

  return bbaInstance.get(url);
};

export const deleteUserImage = ({ imageId }) => {
  const url = '/user/image';
  const params = {
    image_id: imageId,
  };

  return bbaInstance.delete(url, { params });
};

export const reAuthenticate = async (email, isWJ = false) => {
  const url = 'hubspot/get-auto-login-token';
  const {
    data: { token: autoLoginToken },
  } = await dsInstance.get(url);
  const urlAccessToken = 'auth/token';
  const response = await bbaInstance.get(urlAccessToken, {
    params: {
      token: autoLoginToken,
      username: email,
    },
  });

  const { token, refresh_token: refreshToken, properties } = response?.data?.data || {};
  setItem('token', token);
  setItem('refreshToken', refreshToken);
  setItem('userData', properties);
  return {
    userData: properties,
    token,
    refreshToken,
  };
};
