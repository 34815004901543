export default function LetterIcon({ width = 16, height = 16 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 10.5L10.1986 12.7489C12.4944 14.662 13.6423 15.6186 15 15.6186C16.3577 15.6186 17.5056 14.662 19.8014 12.7488L22.5 10.5M12.5 25.5H17.5C22.214 25.5 24.5711 25.5 26.0355 24.0355C27.5 22.5711 27.5 20.214 27.5 15.5C27.5 10.786 27.5 8.42893 26.0355 6.96447C24.5711 5.5 22.214 5.5 17.5 5.5H12.5C7.78595 5.5 5.42893 5.5 3.96447 6.96447C2.5 8.42893 2.5 10.786 2.5 15.5C2.5 20.214 2.5 22.5711 3.96447 24.0355C5.42893 25.5 7.78595 25.5 12.5 25.5Z"
        stroke="#4CC9E1"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
}
