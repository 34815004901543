import { withRouter } from 'react-router';
import { Menu } from 'antd';
import { NavItem, NavLink, NavSubMenu } from 'modules/common/components/UIComponents';

import {
  getRouteAllCampaignMetrics,
  getRouteEmailSenderEmailConfiguration,
  getRouteEmailTemplates,
  getRouteLaunchCampaign,
  getRouteLeads,
} from 'modules/dashboard/routes/navigation';
import useEmailCampaignStatus from 'v2/hooks/useEmailCampaignStatus';

const DashboardSideNav = ({ location }) => {
  const hasUsedEmailCampaigns = useEmailCampaignStatus();

  return (
    <Menu
      mode="inline"
      selectedKeys={location.pathname.split('/')}
      defaultOpenKeys={location.pathname.split('/')}
    >
      <NavItem key="leads">
        <NavLink to={getRouteLeads()}>
          <span>Leads</span>
        </NavLink>
      </NavItem>
      {hasUsedEmailCampaigns && (
        <NavSubMenu key="email-campaign" title={<span>Email Campaign</span>}>
          <NavItem key="sender-email-configuration">
            <NavLink to={getRouteEmailSenderEmailConfiguration()}>
              Sender Email Configuration
            </NavLink>
          </NavItem>

          <NavItem key="templates">
            <NavLink to={getRouteEmailTemplates()}>Template Emails</NavLink>
          </NavItem>

          <NavItem key="launch-campaign">
            <NavLink to={getRouteLaunchCampaign()}>Launch Email Campaign</NavLink>
          </NavItem>

          <NavItem key="campaign-metrics">
            <NavLink to={getRouteAllCampaignMetrics()}>Campaign Metrics</NavLink>
          </NavItem>
        </NavSubMenu>
      )}
    </Menu>
  );
};

export default withRouter(DashboardSideNav);
